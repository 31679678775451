import { Grid, LinearProgress, Link, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { APIContext } from "../../Contexts/APIContext/APIContext";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { IUniversidadParam } from "../../Interfaces/UrlParams/IUniversidadParam";
import { MainContainerStyler } from "../../_Styles/MainContainerStyler";
import "../../index.css";
import { env } from "process";




export const MainContainerPage = () => {


    const styler = MainContainerStyler;

    const { AppState } = useContext(AppContext);

    const {
        Universidad
    } = AppState;

    return (

        <>
            <Grid container sx={styler.mainBodyBG} spacing={0}>
                <Grid item xs={12} container>
                    <Grid item xs={12} sm={4} md={2} sx={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", marginBottom: { xs: "1rem", md: "0" } }}>
                        {
                            Universidad.urlLogoUniversidad &&
                            <img src={`${process.env.PUBLIC_URL}/${Universidad.urlLogoUniversidad}`} />
                        }
                    </Grid>
                    <Grid item xs={12} sm={8} md={10} sx={{ display: "flex", alignContent: "center", alignItems: "center" }}>
                        <Typography variant="h5" color="white" sx={{ textTransform: "uppercase" }}>
                            Simulador de Examen de Admisión
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent="center" sx={{ position: "relative", marginTop: "1rem", marginBottom: "1rem", flexGrow: "1 !important" }}>
                    <Outlet />
                </Grid>
                <Grid item xs={12} container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Link sx={{ color: "primary.contrastText" }} href={Universidad.urlPoliticaPrivacidad} target="_blank">
                            <Typography variant="subtitle1">
                                Consulta la politica de privacidad
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" textAlign="right" color="white">
                            Todos los derechos reservados
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>

    );

};