import axios from "axios";
import { IBaseDataRecord } from "../../Interfaces/Models/IBaseDataRecord";
import { IExamenTipoRecord } from "../../Interfaces/Models/IExamenTipoRecord";
import { IModoExamenRecord } from "../../Interfaces/Models/IModoExamenRecord";
import { IUniversidadData } from "../../Interfaces/Models/IUniversidadData";
import { IUniversidadConnector } from "../Interfaces/IUniversidadConnector";
import { BaseConnector } from "./BaseConnector";



export class UniversidadConnector extends BaseConnector implements IUniversidadConnector {


    constructor() {
        super();

    }


    public async GetModosExamen(idTipoExamen: string): Promise<IModoExamenRecord[]> {

        const response = await axios.get(`ExamenesModo?tipoExamen=${idTipoExamen}`);
        // console.info("Axios Reponse", response.data);
        return response.data;

    }

    public async GetTiposExamen(idUniversidad: string): Promise<IExamenTipoRecord[]> {

        const response = await axios.get(`ExamenesTipos?universidadId=${idUniversidad}`);
        // console.info("Axios Reponse", response.data);
        return response.data;

    }

    public async GetUniversidadData(idUniversidad: string): Promise<IUniversidadData> {

        const response = await axios.get(`/Universidad?id=${idUniversidad}`);
        // console.info("Axios Reponse", response.data);
        return response.data;

    }


}