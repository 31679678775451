import { createTheme } from "@mui/material/styles";


let baseTheme = createTheme();
export const DefaultTheme = createTheme(baseTheme, {
    palette: {
        ...baseTheme.palette,
        primary: {
            main: '#1d4e99',
            light: '#4a71ad',
            dark: '#14366b',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#1d8d99',
            light: '#4aa3ad',
            dark: '#14626b',
            contrastText: '#ffffff',
        },
    }
});