import { faClock } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { useState } from "react";
import { IWithChildren } from "../../../Interfaces/IWithChildren";




export interface ILoaderIndicatorProps extends IWithChildren {

    message: string;
    isLoading: boolean;

}


export const LoaderIndicator = ({

    message,
    isLoading,
    children

}: ILoaderIndicatorProps) => {

    return (
        <>
            {
                isLoading ? (
                    <Grid container spacing={3}>
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h6">
                                <FontAwesomeIcon icon={faClock} /> {message}
                            </Typography>
                            <LinearProgress />
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        {children}
                    </>
                )
            }
        </>
    );

}