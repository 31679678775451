import axios from "axios";
import { IBateriaData } from "../../Interfaces/Models/IBateriaData";
import { ICodeResponse } from "../../Interfaces/Models/ICodeResponse";
import { IExamenDetalleUsuario } from "../../Interfaces/Models/IExamenDetalleUsuario";
import { IRegistroExamenRecord } from "../../Interfaces/Models/IRegistroExamenRecord";
import { IUserRespuesta } from "../../Interfaces/Models/IUserRespuesta";
import { IPreguntaConnector } from "../Interfaces/IPreguntaConnector";
import { BaseConnector } from "./BaseConnector";





export class PreguntaConnector extends BaseConnector implements IPreguntaConnector {


    constructor() {
        super();

    }

    public async GetRespuestasExamenById(chainId: string, idExamen: number): Promise<IExamenDetalleUsuario[]> {

        const response = await axios.get(`/ExamenRespuesta?chainRegistro=${chainId}&idRegistro=${idExamen}`);

        return response.data;


    }

    public async UpdateRespuestasUsuario(idExamen: string, fechaFin: string, resultado: string, respuestas: IUserRespuesta[], idEstatus: number): Promise<ICodeResponse> {

        const response = await axios.put('/UserRespuestas', {
            "idExamen": idExamen,
            "fechaFin": fechaFin,
            "resultado": resultado,
            "detalleExamen": respuestas,
            "idEstatus": idEstatus
        });

        return response.data;

    }

    public async GetRespuestasUsuarioByExamenId(idExamen: string): Promise<IUserRespuesta[]> {

        const response = await axios.get(`/UserRespuestas?idExamen=${idExamen}`);
        // console.info("respuetas usaario", response.data);
        return response.data;

    }

    public async SendRespuestasUsuarios(idUsuario: string, fechaInicio: string, fechaFin: string, resultado: string, respuestas: IUserRespuesta[], idTipoExamen: number, idEstatus: number): Promise<ICodeResponse> {

        const response = await axios.post('/UserRespuestas', {
            "usuarioId": idUsuario,
            "fechaInicio": fechaInicio,
            "fechaFin": fechaFin,
            "resultado": resultado,
            "detalleExamen": respuestas,
            "idTipoExamen": idTipoExamen,
            "idEstatus": idEstatus
        });

        return response.data;

    }


    public async GetExamenesUsuarios(idUsuario: string, idTipoExamen: number, numRegistros: number): Promise<IRegistroExamenRecord[]> {

        const response = await axios.get(`/ExamenesUsuario?idUsuario=${idUsuario}&idTipoExamen=${idTipoExamen}&records=${numRegistros}`);
        // console.info(response.data);
        return response.data;

    }

    public async GetPreguntasByExamenType(examenType: string): Promise<IBateriaData> {

        const response = await axios.get(`/ExamenesBateria?idTipoExamen=${examenType}`);
        // console.info(response.data);
        return response.data;

    }


}