import { faEnvelope, faEye, faLockOpenAlt, faSend } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography, TextField, InputAdornment } from "@mui/material";
import { Link } from "react-router-dom";
import { HugeButton } from "../../xStyled/HugeButton";




export const ResetPasswordForm = () => {

    return (
        <Grid container spacing={3} sx={{ marginRight: { xs: "0.1rem", md: "20rem" } }}>
            <Grid item xs={12}>
                <Typography variant="h5" textAlign="center">
                    Restablecer Contraseña
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Correo Electrónico"
                    variant="outlined"
                    type="text"
                    required
                    fullWidth
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={6} md={6}>
                <HugeButton variant="contained" fullWidth color="secondary">
                    <Typography variant="body1">
                        <FontAwesomeIcon icon={faSend} /> Solicitar Modificación
                    </Typography>
                </HugeButton>
            </Grid>
        </Grid>
    );


}