import { Grid, Typography } from "@mui/material";
import { useRouteError } from "react-router-dom";




export const PageGeneralError = () => {

    const pageError = useRouteError();
    console.info("Error", pageError);

    return (
        <Grid container justifyContent="center" alignItems="center"
            sx={{
                padding: "1rem",
                background: `url('${process.env.PUBLIC_URL}/UI/404Error.jpg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            }}>
            <Grid item xs={12} md={6} container>
                <Grid item xs={12}>
                    <Typography variant="h4" color="white" textAlign="center">
                        ERROR
                    </Typography>
                    <Typography variant="h5" color="white" textAlign="center">
                        ¡Lo sentimos, Ocurrio un error, por favor regresa a la página principal!.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "1.5rem" }}>
                    <Typography variant="body1" color="white" textAlign="center">
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
            </Grid>
        </Grid>
    );

}