import { faEyeSlash, faPencilAlt } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { APIContext } from "../../../Contexts/APIContext/APIContext";
import { AppContext } from "../../../Contexts/AppContext/AppContext";
import { useLoading } from "../../../Hooks/useLoading";
import { IRegistroExamenRecord } from "../../../Interfaces/Models/IRegistroExamenRecord";
import { GLOBALS } from "../../../Models/config/Globals";
import { IntentoTableCell, IntentoTableRow } from "../../xStyled/IntentoTableCell";
import { IListIntentosExamenesProps } from "./IListIntentosExamenesProps";
import { Link as RouterLink } from 'react-router-dom';
import { faTable } from "@fortawesome/free-solid-svg-icons";




export const ListIntentosExamenes = ({

    ExamenId,
    IsTest

}: IListIntentosExamenesProps) => {


    const { APIService } = useContext(APIContext);
    const { AppState } = useContext(AppContext);

    const [isLoading, setIsLoading] = useLoading(true);
    const [headers, SetHeaders] = useState<string[]>([]);
    const [intentos, setIntentos] = useState<IRegistroExamenRecord[]>([]);


    const LoadIntentos = async () => {

        const response = await APIService.Preguntas.GetExamenesUsuarios(AppState.User.chainId, ExamenId, 5);
        setIntentos(response);

    };


    const formatDuracion = (fechaTermino: string, fechaInicio: string) => {

        const fTermino = moment(fechaTermino);
        const fInicio = moment(fechaInicio);
        const duration = moment.duration(fTermino.diff(fInicio));

        return duration.asMinutes();

    };


    const calcAvance = (total: string) => {

        const preguntas = total.split("/");
        const contestado = preguntas[0].trim();
        const totalPreguntas = preguntas[1].trim();

        const porcentaje = (Number(contestado) / Number(totalPreguntas)) * 100;

        return `${porcentaje.toFixed(2)} %`;

    }


    useEffect(() => {

        if (ExamenId != 0) {

            const baseHeaders = [
                "Intento",
                "Fecha",
                "Preguntas Respondidas",
                "Tiempo Empleado",
                "% Aciertos"
            ];

            if (IsTest) {
                baseHeaders.push("Acciones");
            }

            SetHeaders(baseHeaders);

            LoadIntentos();
        }

    }, [ExamenId]);

    return (

        <>
            {
                ExamenId != 0 &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <TableHead>
                                <TableRow>
                                    {
                                        headers.map(header => (
                                            <IntentoTableCell>{header}</IntentoTableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    intentos.length == 0 ? (
                                        <IntentoTableRow>
                                            <IntentoTableCell colSpan={headers.length}>
                                                <Typography variant="body1">
                                                    <FontAwesomeIcon icon={faEyeSlash} /> Sin registros
                                                </Typography>
                                            </IntentoTableCell>
                                        </IntentoTableRow>
                                    ) : (
                                        intentos.map((intento, index) => (
                                            <IntentoTableRow key={`intento-${intento.chainId}`}>
                                                <IntentoTableCell >
                                                    <Typography variant="body1">
                                                        {index + 1}
                                                    </Typography>
                                                </IntentoTableCell>
                                                <IntentoTableCell >
                                                    <Typography variant="body1">
                                                        {moment(intento.fechaInicio).format(GLOBALS.FORMAT_FECHA)}
                                                    </Typography>
                                                </IntentoTableCell>
                                                <IntentoTableCell >
                                                    <Typography variant="body1">
                                                        {intento.resultado}
                                                    </Typography>
                                                </IntentoTableCell>
                                                <IntentoTableCell >
                                                    <Typography variant="body1">
                                                        {formatDuracion(intento.fechaTermino, intento.fechaInicio)} Minutos
                                                    </Typography>
                                                </IntentoTableCell>
                                                <IntentoTableCell>
                                                    <Typography variant="body1" >
                                                        {calcAvance(intento.resultado)}
                                                    </Typography>
                                                </IntentoTableCell>
                                                {
                                                    IsTest &&
                                                    <IntentoTableCell>
                                                        {
                                                            intento.idEstatus != GLOBALS.ID_ESTATUS_COMPLETADO ? (
                                                                <Button variant="contained" color="primary" component={RouterLink} to={`../examen-prueba/${intento.chainId}?date=${intento.fechaInicio}`}>
                                                                    <Typography variant="body1">
                                                                        <FontAwesomeIcon icon={faPencilAlt} /> Continuar
                                                                    </Typography>
                                                                </Button>
                                                            ) : (
                                                                <Typography variant="body1" textAlign="center">
                                                                    <Button variant="contained" color="secondary" component={RouterLink} to={`../detalle-examen/${intento.chainId}?id=${intento.idRegistro}&date=${intento.fechaInicio}`}>
                                                                        <Typography variant="body1">
                                                                            <FontAwesomeIcon icon={faTable} /> Ver Detalle
                                                                        </Typography>
                                                                    </Button>
                                                                </Typography>
                                                            )
                                                        }
                                                    </IntentoTableCell>

                                                }
                                            </IntentoTableRow>
                                        ))
                                    )
                                }
                            </TableBody>
                        </TableContainer>
                    </Grid>
                </Grid >
            }
        </>

    );

}