import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faEyeSlash, faLockOpenAlt, faSave, faSchool, faUser } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, InputAdornment, LinearProgress, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { APIContext } from "../../../Contexts/APIContext/APIContext";
import { AppContext } from "../../../Contexts/AppContext/AppContext";
import { useLoading } from "../../../Hooks/useLoading";
import { useUserDataRecord } from "../../../Hooks/useUserDataRecord/useUserDataRecord";
import { DialogError } from "../../Reusable/DialogError/DialogError";




export const PerfilForm = () => {


    const { AppState, UpdateUserData } = useContext(AppContext);
    const { APIService } = useContext(APIContext);
    const [isLoading, setIsLoading] = useLoading(false);

    const [showDialogUpdate, setShowDialogUpdate] = useState<boolean>(false);

    const {
        UserDataRecord,
        SetUserDataRecord,
        SetUserDataPropChange,
        OnUserDataRadioChange,
        OnUserDataCheckBoxChange,
        IsUserDataValid,
        IsUserStrPropValid,
        IsPasswordSame,
        CheckValidEmail
    } = useUserDataRecord(AppState.User);


    const updateDataRequest = async () => {

        setIsLoading(true);
        const response = await APIService.UserAuth.UpdateUser(UserDataRecord);
        // console.info("Respuesta Service", response);
        UpdateUserData(UserDataRecord);
        setIsLoading(false);
        setShowDialogUpdate(true);


    };


    useEffect(() => {

        // console.info("User Data", UserDataRecord);

    }, []);


    return (
        <>
            <Grid item xs={12} container spacing={3} sx={{ padding: "1rem" }}>
                {
                    isLoading ? (
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Datos Personales
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Escuela de procedencia"
                                        variant="outlined"
                                        type="text"
                                        required
                                        fullWidth
                                        name="escuelaProcedencia"
                                        error={IsUserStrPropValid("escuelaProcedencia")}
                                        value={UserDataRecord.escuelaProcedencia}
                                        onChange={e => {
                                            const { value, name } = e.target;
                                            SetUserDataPropChange(value, name);
                                        }}
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                                    <FontAwesomeIcon icon={faSchool} />
                                                </InputAdornment>
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Nombre Completo"
                                        variant="outlined"
                                        type="text"
                                        required
                                        fullWidth
                                        name="nombreCompleto"
                                        error={IsUserStrPropValid("nombreCompleto")}
                                        value={UserDataRecord.nombreCompleto}
                                        onChange={e => {
                                            const { value, name } = e.target;
                                            SetUserDataPropChange(value, name);
                                        }}
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                                    <FontAwesomeIcon icon={faUser} />
                                                </InputAdornment>
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Whatsapp"
                                        variant="outlined"
                                        type="tel"
                                        required
                                        fullWidth
                                        name="telefono"
                                        error={IsUserStrPropValid("telefono")}
                                        value={UserDataRecord.telefono}
                                        onChange={e => {
                                            const { value, name } = e.target;
                                            SetUserDataPropChange(value, name);
                                        }}
                                        inputProps={{
                                            maxLength: 10
                                        }}
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                                    <FontAwesomeIcon icon={faWhatsapp} />
                                                </InputAdornment>
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Cambiar Contraseña"
                                        variant="outlined"
                                        type="password"
                                        required
                                        fullWidth
                                        name="password"
                                        error={IsUserStrPropValid("password")}
                                        value={UserDataRecord.password}
                                        onChange={e => {
                                            const { value, name } = e.target;
                                            SetUserDataPropChange(value, name);
                                        }}
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                                    <FontAwesomeIcon icon={faEyeSlash} />
                                                </InputAdornment>
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )
                }
                <Grid item xs={12} container spacing={3} sx={{ marginTop: "1rem" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", flexGrow: "1" }}>
                        <Button variant="contained" onClick={updateDataRequest}>
                            <Typography variant="subtitle2">
                                <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid >
            <Grid item xs={12}>
                <DialogError
                    Title="Datos Actualizados"
                    Messages={[
                        "Los datos han sido actualizados."
                    ]}
                    OnClose={() => { setShowDialogUpdate(false); }}
                    ShouldShow={showDialogUpdate}
                />
            </Grid>
        </>
    );

};