import { useContext, useState } from "react";
import { APIContext } from "../Contexts/APIContext/APIContext";
import { IPreguntaData } from "../Interfaces/Models/IPreguntaData";
import { IUserRespuesta } from "../Interfaces/Models/IUserRespuesta";



export const useUserRespuestas = () => {

    const [userRespuestas, setUserRespuestas] = useState<IUserRespuesta[]>([]);
    const { APIService } = useContext(APIContext);

    const LoadUserRespuestaPrevias = async (idExamen: string, preguntas: IPreguntaData[]) => {

        const respuestasPrevias = await APIService.Preguntas.GetRespuestasUsuarioByExamenId(idExamen);
        respuestasPrevias.map(userRespuesta => {

            for (let index = 0; index < preguntas.length; index++) {
                const pregunta = preguntas[index];
                if (pregunta.idPregunta == userRespuesta.idPregunta) {

                    const posiblesRespuestas = pregunta.respuestas;

                    for (let jIndex = 0; jIndex < posiblesRespuestas.length; jIndex++) {
                        const respuesta = posiblesRespuestas[jIndex];
                        if (respuesta.idRespuesta == userRespuesta.idRespuesta) {
                            userRespuesta.isCorrecta = respuesta.correcta;
                        }
                    }

                };
            }

        });

        // console.info("Respuestas cargadas", respuestasPrevias);

        setUserRespuestas(respuestasPrevias);

    };


    const updateUserResponse = (pregunta: number, respuesta: number, correcta: boolean, respuestaText: string) => {

        const tmpRespuestas = [...userRespuestas];

        for (let index = 0; index < userRespuestas.length; index++) {
            const cRespuesta = userRespuestas[index];
            if (cRespuesta.idPregunta == pregunta) {

                cRespuesta.idRespuesta = respuesta;
                cRespuesta.isCorrecta = correcta;
                tmpRespuestas[index] = cRespuesta;
                setUserRespuestas(tmpRespuestas);
                return;
            }
        }

        setUserRespuestas([
            ...userRespuestas, {
                idPregunta: pregunta,
                idRespuesta: respuesta,
                isCorrecta: correcta
            }
        ]);

        // console.info("User Respuestas", userRespuestas);

    }


    const GetResult = () => {

        let correctas = 0;

        for (let index = 0; index < userRespuestas.length; index++) {
            const respuesta = userRespuestas[index];
            if (respuesta.isCorrecta) {
                correctas++;
            }

        }

        return correctas;

    };


    return [
        userRespuestas,
        updateUserResponse,
        GetResult,
        LoadUserRespuestaPrevias
    ] as const;

};