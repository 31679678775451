import { faFolderUser, faUserAlt, faUserPen } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Grid, LinearProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { APIContext } from "../../Contexts/APIContext/APIContext";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { StylerContext } from "../../Contexts/StylerContext/StylerContext";
import { useLoading } from "../../Hooks/useLoading";
import { IUniversidadParam } from "../../Interfaces/UrlParams/IUniversidadParam";


export const HomePage = () => {

    const { AppState } = useContext(AppContext);
    const { APIService } = useContext(APIContext);
    const { UpdateStyler } = useContext(StylerContext);
    const { UpdateUniversidadData } = useContext(AppContext);

    const navigator = useNavigate();
    const { idUniversidad } = useParams<IUniversidadParam>();
    const [isLoading, setIsLoading] = useLoading(true);

    const {
        Universidad
    } = AppState;

    const LoadUniversidadData = async () => {

        try {

            const uniData = await APIService.Universidades.GetUniversidadData(idUniversidad as string);
            if (uniData.chainId != undefined) {

                UpdateUniversidadData(uniData);
                UpdateStyler(uniData.nombreThema);
                setIsLoading(false);

            } else {

                setIsLoading(false);
                navigator("/404");

            }

        } catch (e) {

            setIsLoading(false);
            navigator("/404");

        }

    };

    useEffect(() => {

        const getData = async () => {
            await LoadUniversidadData();
        }

        getData().catch(console.error);

    }, []);

    return (
        <>
            {
                isLoading ? (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <Card>
                            <Grid container spacing={0} sx={{ height: "100%", marginTop: "1.5rem" }}>
                                <Grid item xs={12} container spacing={0}>
                                    <Grid item xs={12} md={6} container spacing={0} sx={{
                                        paddingLeft: { xs: "1rem !important", md: "6rem !important" },
                                        paddingRight: { xs: "1rem !important", md: "6rem !important" },
                                        marginBottom: "2rem !important"
                                    }}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                ¿Estás a punto de realizar tu examen de ingreso? Entonces esto te interesa…
                                            </Typography>
                                            <Typography variant="body1" textAlign="justify">
                                                Averigua si estás listo/a para realizarlo con un examen de práctica o una prueba tipo simulador para descubrir si estás listo para tu prueba de ingreso.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                ¿Cuál es el objetivo de esto?
                                            </Typography>
                                            <Typography variant="body1" textAlign="justify">
                                                Queremos ofrecerte una herramienta que te brinde lo que necesitas para perder los nervios y entrar seguro a una de las pruebas más importantes de tu vida académica. Aunque este examen no asegura al 100% que la vayas a armar, te aseguramos que al menos te podrá dar un vistazo de que tan listo estás.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                ¿Cómo funciona?
                                            </Typography>
                                            <Typography variant="body1" textAlign="justify">
                                                ¡Pilas! Porque aunque los pasos son sencillos te recomendamos dejar un rato Tik Tok y poner atención a los siguientes pasos:
                                            </Typography>
                                            <Typography variant="body1" component="div" textAlign="justify">
                                                <ol>
                                                    <li>Regístrate con tus datos completos (nombre y mail)</li>
                                                    <li>Confirma tu correo y contraseña</li>
                                                    <li>Ingresa a nuestra página</li>
                                                    <li>Selecciona el tipo de prueba que quieres hacer: simulador o examen práctico.</li>
                                                </ol>
                                                <ul>
                                                    <li>Recuerda que esta prueba sólo es un material de apoyo.</li>
                                                    <li>No garantiza tus resultados al momento de presentar tu examen oficial.</li>
                                                    <li>Es un material gratuito que puedes usar cuantas veces requieras. </li>
                                                </ul>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} container spacing={3}>
                                            <Grid item xs={6}>
                                                <Button component={RouterLink} to={`registro`} variant="contained" color="primary" fullWidth>
                                                    <Typography variant="body1">
                                                        <FontAwesomeIcon icon={faFolderUser} /> Registrarse
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button component={RouterLink} to={`login`} variant="contained" color="secondary" fullWidth>
                                                    <Typography variant="body1">
                                                        <FontAwesomeIcon icon={faUserPen} /> Iniciar Sesión
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6} container spacing={3} sx={{
                                        background: `url('${process.env.PUBLIC_URL + "/UI/HomeImage.jpg"}')`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        height: { xs: "25rem", sm: "100%" }
                                    }}>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </>
                )
            }
        </>
    );

};