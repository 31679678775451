import { createContext, useState } from "react";
import { IWithChildren } from "../../Interfaces/IWithChildren";
import { APIConnector } from "../../Services/Connectors/APIConnector";
import { IAPIConnector } from "../../Services/Interfaces/IAPIConnector";



export interface IAPIContextHandlers {

    APIService: IAPIConnector;

}

export const APIContext = createContext<IAPIContextHandlers>({} as IAPIContextHandlers);

export const APIContextProvider = ({ children }: IWithChildren) => {

    const [apiService, setApiService] = useState<IAPIConnector>(new APIConnector());

    return (
        <APIContext.Provider value={{ APIService: apiService }}  >
            {children}
        </APIContext.Provider>
    );

}