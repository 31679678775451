import { createTheme } from "@mui/material/styles";
import { BaseStyleDefiner } from "./BaseStyleDefiner";


let baseTheme = createTheme();
export const UPOTheme = createTheme(baseTheme, {
    palette: {
        ...baseTheme.palette,
        primary: {
            main: '#161922',
            dark: '#0f1117',
            light: '#44474e',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#0074c9',
            light: '#338fd3',
            dark: '#00518c',
            contrastText: '#ffffff',
        },
    }
});

export class UPOStyleDefinition extends BaseStyleDefiner {

    constructor() {
        super(
            "upo-style",
            UPOTheme
        );
    }

}