import { createTheme } from "@mui/material/styles";
import { BaseStyleDefiner } from "./BaseStyleDefiner";


let baseTheme = createTheme();
export const UnidepTheme = createTheme(baseTheme, {
    palette: {
        ...baseTheme.palette,
        primary: {
            main: '#003a5c',
            light: '#33617c',
            dark: '#002840',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#054032',
            light: '#37665b',
            dark: '#032c23',
            contrastText: '#ffffff',
        },
    }
});


export class UnidepStyleDefinition extends BaseStyleDefiner {

    constructor() {
        super(
            "unidep-style",
            UnidepTheme
        );
    }


}