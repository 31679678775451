import moment from "moment";
import { useContext } from "react";
import { APIContext } from "../Contexts/APIContext/APIContext";
import { AppContext } from "../Contexts/AppContext/AppContext";
import { ICRMRecord } from "../Interfaces/Models/ICRMRecord";
import { IUserData } from "../Interfaces/Models/IUserData";




export const useCRMConnector = () => {


    const { APIService } = useContext(APIContext);
    const { AppState } = useContext(AppContext);

    const {

        urlCRM,
        nombre

    } = AppState.Universidad;



    const SendToCRM = async (userData: IUserData, qVinculador: string | null = null): Promise<void> => {

        // console.info("Valor del vinculador", qVinculador);

        const toSend: ICRMRecord = {
            email: userData.correoElectronico,
            medio: "Simulador Examen",
            escuela_procedencia: userData.escuelaProcedencia,
            nombre_completo: userData.nombreCompleto,
            telefono: userData.telefono,
            universidad: nombre,
            date_formatted: moment().format("DD/MM/YYYY"),
            isVinculador: qVinculador ? true : false
        };
        const response = await APIService.CRMConnector.RegisterCRMData(
            urlCRM,
            toSend
        );

    };

    return [
        SendToCRM
    ] as const;


};