



export class GLOBALS {

    public static DRAWER_WIDTH: number = 240;

    public static BANNER_VERTICAL: number = 100;
    public static BANNER_HORIZONTAL: number = 150;

    public static API_URL: string = "https://ng-simulador.azurewebsites.net/Core/api/"
    public static APP_VERSION: string = "1.0.0-alpha";

    public static YES_VALUE: string = "Si";
    public static NO_VALUE: string = "No";

    public static SUCCESS_CODE: number = 200;
    public static ERROR_CODE: number = 500;

    public static GUID_DEFAULT: string = "00000000-0000-0000-0000-000000000000";

    public static ID_MODO_SIMULADOR: number = 2;
    public static ID_MODO_PRACTICA: number = 1;

    public static ID_ESTATUS_COMPLETADO: number = 1;
    public static ID_ESTATUS_NO_COMPLETADO: number = 2;

    public static FORMAT_HOURS: string = "hh:mm:ss";
    public static FORMAT_FECHA: string = "YYYY-MM-DD";

    public static TIMER_UNLIMITED = 1000;
    public static TIMER_LIMITED = 2;

    public static NO_SELECTED_OPTION: number = -1;

    public static EMAIL_REG_EXP: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

}