import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Contexts/AppContext/AppContext";
import { useDidMount } from "../../../Hooks/useDidMount";
import { IToggleExamenTipoProps } from "./IToggleExamenTipoProps";




export const ToggleExamenTipo = ({

    Options,
    OnToggleChange

}: IToggleExamenTipoProps) => {

    const { AppState } = useContext(AppContext);
    const [currentOption, setCurrentOption] = useState<string>(Options.length > 0 ? Options[0].chainId : "");
    const { didMount } = useDidMount();


    const onChangeSelection = (event: React.MouseEvent<HTMLElement>, newValue: string) => {

        if (newValue !== null && newValue != currentOption) {
            setCurrentOption(newValue);
        }

    }

    const getSelectedOption = (chainId: string) => {

        const option = Options.filter(item => {
            return item.chainId == chainId;
        });

        if (option.length > 0) {
            return option[0];
        }

        return undefined;

    }

    useEffect(() => {

        if (didMount.current) {

            const option = getSelectedOption(currentOption);
            if (option) {
                OnToggleChange(option);
            }

        }

    }, [currentOption])


    return (
        <ToggleButtonGroup
            color="primary"
            value={currentOption}
            exclusive
            onChange={onChangeSelection}
        >
            {
                Options.map(item => (
                    <ToggleButton key={item.chainId} value={item.chainId}>{item.nombre}</ToggleButton >
                ))
            }
        </ToggleButtonGroup>

    );

};