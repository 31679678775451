import { faFilePlus } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BannerRender } from "../../Components/Reusable/Banners/BannerRenders";
import { ListIntentosExamenes } from "../../Components/Reusable/ListIntentosExamenes/ListIntentosExamenes";
import { ToggleExamenTipo } from "../../Components/Reusable/ToggleExamenTipo/ToggleExamenTipo";
import { APIContext } from "../../Contexts/APIContext/APIContext";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { useLoading } from "../../Hooks/useLoading";
import { IExamenTipoRecord } from "../../Interfaces/Models/IExamenTipoRecord";
import { IModoExamenRecord } from "../../Interfaces/Models/IModoExamenRecord";
import { GAnalReporter, GAEVENTNAMES, GAReporterDataContanier } from "../../Models/config/GEventHandler";
import { GLOBALS } from "../../Models/config/Globals";




export const MisIntentosPage = () => {

    const { AppState, UpdateTipoExamen, UpdateExamenId } = useContext(AppContext);
    const navigator = useNavigate();
    const { APIService } = useContext(APIContext);
    const [isLoading, setIsLoading] = useLoading(true);
    const [examenesOptions, setExamenesOptions] = useState<IExamenTipoRecord[]>([]);
    const [modosDisponibles, setModosDisponibles] = useState<IModoExamenRecord[]>([]);


    const LoadModalidad = async () => {

        const response = await APIService.Universidades.GetTiposExamen(AppState.Universidad.chainId);
        setExamenesOptions(response);
        setIsLoading(false);

    };

    const OnExamenTipoChange = async (record: IExamenTipoRecord) => {

        UpdateTipoExamen(record);
        const modos = await APIService.Universidades.GetModosExamen(record.chainId);
        setModosDisponibles(modos);

    };

    const CheckExamenModo = (idModo: number) => {

        const disponibles = modosDisponibles.filter(item => {
            return item.idModo == idModo;
        });

        return disponibles.length > 0 ? disponibles[0].id : 0;

    };


    const navigateToExameSet = (idExamen: number, route: string) => () => {

        GAnalReporter(
            AppState.TipoExamen.nombre,
            new GAReporterDataContanier(
                AppState.Universidad.nombre,
                GAEVENTNAMES.EXAMEN_REALIZAR
            ).ToJSON()
        );

        UpdateExamenId(idExamen);
        navigator(route);


    };


    useEffect(() => {

        LoadModalidad();

    }, []);

    return (
        <Grid container spacing={0} sx={{ padding: "1rem" }}>
            {
                isLoading ? (
                    <Grid item xs={12}>
                        <Typography variant="h6" textAlign="center">
                            Obteniendo Examenes Disponibles...
                        </Typography>
                        <LinearProgress />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" textAlign="center">
                                        ¡Hey! Qué gusto verte por acá
                                    </Typography>
                                    <Typography variant="body1" textAlign="center">
                                        Elige la opción que más vaya con tus objetivos, ¡y aplícate cómo si fuera el día del examen!
                                    </Typography>
                                </Grid >
                                <Grid item xs={12} container spacing={3}>
                                    <Grid item xs={12}>
                                        <ToggleExamenTipo
                                            Options={examenesOptions}
                                            OnToggleChange={OnExamenTipoChange}
                                        />
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Simulador
                                    </Typography>
                                    <Typography variant="h6">
                                        Descubre si estás listo/a para tu examen de admisión.
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        No dejes para mañana lo que puedes mejorar hoy, toma el examen con límite de tiempo y comienza a enfrentarte a esos nervios.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ListIntentosExamenes
                                        ExamenId={CheckExamenModo(GLOBALS.ID_MODO_SIMULADOR)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button color="secondary" variant="contained" onClick={navigateToExameSet(CheckExamenModo(GLOBALS.ID_MODO_SIMULADOR), "../examen-simulacion")}>
                                        <Typography variant="subtitle2">
                                            <FontAwesomeIcon icon={faFilePlus} /> Nueva Simulación
                                        </Typography>
                                    </Button>
                                </Grid> */}
                            </Grid >
                        </Grid >
                        <Grid item xs={12} sx={{ marginTop: "2rem" }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Examen de Práctica
                                    </Typography>
                                    <Typography variant="h6">
                                        ¡Pon a prueba tus conocimientos!
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        Ya sea que quieras repasar un tema en particular o hacer una prueba con todo el tiempo del mundo este examen es para ti.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ListIntentosExamenes
                                        ExamenId={CheckExamenModo(GLOBALS.ID_MODO_PRACTICA)}
                                        IsTest
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button color="secondary" variant="contained" onClick={navigateToExameSet(CheckExamenModo(GLOBALS.ID_MODO_PRACTICA), "../examen-prueba")}>
                                        <Typography variant="subtitle2">
                                            <FontAwesomeIcon icon={faFilePlus} /> Nueva Practica
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid >
                        </Grid>
                        <Grid item container xs={12} justifyContent="center">
                            <BannerRender BannerType={GLOBALS.BANNER_HORIZONTAL} />
                        </Grid>
                    </>
                )
            }
        </Grid >
    );

};