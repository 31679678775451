import axios from "axios";
import { GLOBALS } from "../../Models/config/Globals";



export class BaseConnector {

    constructor() {

        axios.defaults.baseURL = GLOBALS.API_URL;

    }

}