import { IAppContextState } from "../../Contexts/AppContext/IAppContextState";
import { IUniversidadData } from "../../Interfaces/Models/IUniversidadData";
import { IUserData } from "../../Interfaces/Models/IUserData";
import { UniversidadDefault } from "./UniversidadDefault";
import { UserDefault } from "./UserDefault";




export const AppStateDefault: IAppContextState = {

    Universidad: {} as IUniversidadData,
    User: {} as IUserData,
    TipoExamen: {
        chainId: "",
        nombre: "Sin Examen Seleccionado"
    },
    ModalidadExamen: "",
    IdExamen: 0

};