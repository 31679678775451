import { useContext, useState } from "react";
import { APIContext } from "../Contexts/APIContext/APIContext";
import { AppContext } from "../Contexts/AppContext/AppContext";
import { ILoginData } from "../Interfaces/Models/ILoginData";
import { IUserData } from "../Interfaces/Models/IUserData";
import { useAuxiliarFunctions } from "./useAuxiliarFunctions";



export const useLoginFunctions = () => {

    const { AppState, UpdateUserData } = useContext(AppContext);
    const { APIService } = useContext(APIContext);

    const [isStrEmpty, isValidEmail] = useAuxiliarFunctions();

    const [loginData, setLoginData] = useState<ILoginData>({
        universidadId: AppState.Universidad.chainId,
        email: "",
        password: ""
    });

    const setLoginPropChange = (value: any, prop: string) => {

        setLoginData({
            ...loginData,
            [prop]: value
        });

    };

    const isLoginStrPropValid = (propName: string): boolean => {

        return isStrEmpty(loginData[propName]);

    };

    const isValidLogin = (): boolean => {

        if (!isValidEmail(loginData.email)) {
            return false;
        }

        if (isStrEmpty(loginData.password)) {
            return false;
        }

        return true;

    };

    const CheckForAuthUser = async (): Promise<IUserData> => {

        const response = await APIService.UserAuth.RequestAuth(loginData);
        UpdateUserData(response);
        return response;

    };

    return [
        loginData,
        setLoginPropChange,
        isValidEmail,
        isValidLogin,
        isLoginStrPropValid,
        CheckForAuthUser
    ] as const;

};