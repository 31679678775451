import { Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../Contexts/AppContext/AppContext";
import { IBanner } from "../../../Interfaces/Models/IBanner";
import { GLOBALS } from "../../../Models/config/Globals";
import { IBannerRenderProps } from "./IBannerRenderProps";

export const BannerRender = ({

    BannerType

}: IBannerRenderProps) => {

    const { AppState } = useContext(AppContext);

    const {
        Universidad
    } = AppState;


    const getBanners = (tipo: number): IBanner[] => {

        const avaliableBanners = Universidad.banners.filter(banner => {
            return banner.tipo == tipo;
        });

        if (avaliableBanners.length > 0) {

            var randomItem = avaliableBanners[Math.floor(Math.random() * avaliableBanners.length)]
            return [randomItem];

        }

        return [];

    };


    return (
        <div style={{ position: "relative" }}>
            {
                !BannerType ? (
                    getBanners(GLOBALS.BANNER_VERTICAL).map(banner => (
                        <a href={`${banner.urlTarget}`} target="_blank">
                            <img style={{ height: "25rem", width: "100%" }} src={`${process.env.PUBLIC_URL}/${banner.descripcion}`} />
                        </a>
                    ))
                ) : (
                    <>
                        {
                            BannerType == GLOBALS.BANNER_HORIZONTAL &&
                            getBanners(GLOBALS.BANNER_HORIZONTAL).map(banner => (
                                <a href={`${banner.urlTarget}`} target="_blank">
                                    <img style={{ width: "100%" }} src={`${process.env.PUBLIC_URL}/${banner.descripcion}`} />
                                </a>
                            ))

                        }
                    </>

                )
            }
            <Typography textAlign="right" variant="caption" sx={{ bottom: 0, right: 0, position: "absolute", width: "100%", background: "#ffffffa1" }}>
                Publicidad
            </Typography>
        </div>
    );

};