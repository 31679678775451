import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { IDialogErrorProps } from "./IDialogErrorProps";




export const DialogError = ({

    ShouldShow,
    OnClose,
    Title,
    Messages

}: IDialogErrorProps) => {

    const handleClose = (event: object, reason: string) => {

        if (reason && reason == "backdropClick")
            return;

        OnClose();

    }

    return (
        <Dialog onClose={handleClose} open={ShouldShow}>
            <DialogTitle>
                {Title}
            </DialogTitle>
            <DialogContent>
                {
                    Messages.map((msg, index) => (
                        <DialogContentText key={`dialog-text-content-${index}`}>
                            {msg}
                        </DialogContentText>
                    ))
                }
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="contained" onClick={OnClose}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );

};