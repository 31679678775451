import { createTheme } from "@mui/material/styles";
import { BaseStyleDefiner } from "./BaseStyleDefiner";


let baseTheme = createTheme();
export const LucernaTheme = createTheme(baseTheme, {
    palette: {
        ...baseTheme.palette,
        primary: {
            main: '#1d4e99',
            light: '#4a71ad',
            dark: '#14366b',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#1d8d99',
            light: '#4aa3ad',
            dark: '#14626b',
            contrastText: '#ffffff',
        },
    }
});

export class LucernaStyleDefinition extends BaseStyleDefiner {

    constructor() {
        super(
            "lucerna-style",
            LucernaTheme
        );
    }


}