import { faFileArchive, faSave } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@mui/material";
import { IButtonSaveExamenProps } from "./IButtonSaveExamenProps";




export const ButtonSaveExamen = ({

    OnSave,
    OnFinalizar

}: IButtonSaveExamenProps) => {

    return (
        <Grid container spacing={3}>
            <Grid item xs={4} sm={4} md={4}>
                <Button fullWidth variant="contained" color="primary" onClick={OnFinalizar}>
                    <Typography variant="body1">
                        <FontAwesomeIcon icon={faFileArchive} /> Finalizar
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
                <Button fullWidth variant="contained" color="primary" onClick={OnSave}>
                    <Typography variant="body1">
                        <FontAwesomeIcon icon={faSave} /> Guardar y Salir.
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );

};