import axios from "axios";
import { ICRMRecord } from "../../Interfaces/Models/ICRMRecord";
import { ICRMConnector } from "../Interfaces/ICRMConnector";




export class CRMConnector implements ICRMConnector {


    constructor() {

    }


    public async RegisterCRMData(urlPost: string, record: ICRMRecord): Promise<void> {

        const response = await axios.post(urlPost, record);
        // console.info("Response CRM", response.data);

    }




}