import { IAPIConnector } from "../Interfaces/IAPIConnector";
import { ICRMConnector } from "../Interfaces/ICRMConnector";
import { IPreguntaConnector } from "../Interfaces/IPreguntaConnector";
import { IUniversidadConnector } from "../Interfaces/IUniversidadConnector";
import { IUserAuthConnector } from "../Interfaces/IUserAuthConnector";
import { CRMConnector } from "./CRMConnector";
import { PreguntaConnector } from "./PreguntaConnector";
import { UniversidadConnector } from "./UniversidadConnector";
import { UserAuthConnector } from "./UserAuthConnector";



export class APIConnector implements IAPIConnector {

    private _Universidades: IUniversidadConnector;
    private _UserAuth: IUserAuthConnector;
    private _Preguntas: IPreguntaConnector;
    private _CRMConnector: ICRMConnector;


    constructor() {

        this._Universidades = new UniversidadConnector();
        this._UserAuth = new UserAuthConnector();
        this._Preguntas = new PreguntaConnector();
        this._CRMConnector = new CRMConnector();

    }

    public get CRMConnector(): ICRMConnector {
        return this._CRMConnector;
    }
    public set CRMConnector(v: ICRMConnector) {
        this._CRMConnector = v;
    }

    public get Preguntas(): IPreguntaConnector {
        return this._Preguntas;
    }
    public set Preguntas(v: IPreguntaConnector) {
        this._Preguntas = v;
    }


    public get UserAuth(): IUserAuthConnector {
        return this._UserAuth;
    }
    public set UserAuth(v: IUserAuthConnector) {
        this._UserAuth = v;
    }


    public get Universidades(): IUniversidadConnector {
        return this._Universidades;
    }
    public set Universidades(v: IUniversidadConnector) {
        this._Universidades = v;
    }



}