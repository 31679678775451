import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faBookAlt, faEnvelope, faEyeSlash, faLockOpenAlt, faSchool, faSend, faTimes, faUser, faWarning } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControl, FormControlLabel, Grid, InputAdornment, LinearProgress, Link as HrefLink, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { APIContext } from "../../../Contexts/APIContext/APIContext";
import { AppContext } from "../../../Contexts/AppContext/AppContext";
import { useCRMConnector } from "../../../Hooks/useCRMConnector";
import { useDidMount } from "../../../Hooks/useDidMount";
import { useLoading } from "../../../Hooks/useLoading";
import { useNewUserRegister } from "../../../Hooks/useNewUserRegister";
import { useUserDataRecord } from "../../../Hooks/useUserDataRecord/useUserDataRecord";
import { ICodeResponse } from "../../../Interfaces/Models/ICodeResponse";
import { GAEVENTNAMES, GAnalReporter, GAReporterDataContanier } from "../../../Models/config/GEventHandler";
import { GLOBALS } from "../../../Models/config/Globals";
import { DialogYesNo } from "../../Reusable/DialogYesNo/DialogYesNo";




export const RegisterForm = () => {

    const { AppState } = useContext(AppContext);
    const [searchParams] = useSearchParams();
    const isVinculador = searchParams.get("vinculador");
    const { didMount } = useDidMount();

    const {
        UserDataRecord,
        SetUserDataPropChange,
        OnUserDataRadioChange,
        IsUserDataValid,
        IsUserStrPropValid,
        IsPasswordSame,
        CheckValidEmail
    } = useUserDataRecord();
    const [isLoading, setIsLoading] = useLoading(false);
    const [createNewUser] = useNewUserRegister();
    const [SendToCRM] = useCRMConnector();
    const [showLogIn, setShowLogIn] = useState<boolean>(false);
    const [showErroMessage, setShowErrorMessage] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<ICodeResponse>({
        code: 0,
        mensaje: ""
    });


    const [showEndDialog, setShowEndDialog] = useState<boolean>(false);
    const navigator = useNavigate();


    const onRegistrarClick = async () => {

        setIsLoading(true);
        setShowErrorMessage(false);
        setShowLogIn(false);

        const response = await createNewUser(UserDataRecord);
        // console.info(response);

        if (response.code == GLOBALS.SUCCESS_CODE) {

            setShowLogIn(true);
            setShowEndDialog(true);
            await SendToCRM(UserDataRecord, isVinculador);
            GAnalReporter(
                AppState.TipoExamen.nombre,
                new GAReporterDataContanier(
                    AppState.Universidad.nombre,
                    GAEVENTNAMES.REGISTRO_REALIZADO
                ).ToJSON()
            );

        } else {

            setErrorMessage(response);
            setShowErrorMessage(true);

        }

        setIsLoading(false);

    };

    useEffect(() => {

        if (didMount.current) {

            // console.info("data universidad inside form", AppState.Universidad);
            // SetUserDataPropChange(AppState.Universidad.chainId, "universidadId");

        }

    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" textAlign="center">
                    Antes de iniciar, registra tus datos en el siguiente formulario.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Escuela de procedencia"
                    variant="outlined"
                    type="text"
                    required
                    fullWidth
                    name="escuelaProcedencia"
                    error={IsUserStrPropValid("escuelaProcedencia")}
                    value={UserDataRecord.escuelaProcedencia}
                    onChange={e => {
                        const { value, name } = e.target;
                        SetUserDataPropChange(value, name);
                    }}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                <FontAwesomeIcon icon={faSchool} />
                            </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Nombre Completo"
                    variant="outlined"
                    type="text"
                    required
                    fullWidth
                    name="nombreCompleto"
                    error={IsUserStrPropValid("nombreCompleto")}
                    value={UserDataRecord.nombreCompleto}
                    onChange={e => {
                        const { value, name } = e.target;
                        SetUserDataPropChange(value, name);
                    }}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                <FontAwesomeIcon icon={faUser} />
                            </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Whatsapp"
                    variant="outlined"
                    type="tel"
                    required
                    fullWidth
                    name="telefono"
                    error={IsUserStrPropValid("telefono")}
                    value={UserDataRecord.telefono}
                    onChange={e => {
                        const { value, name } = e.target;
                        SetUserDataPropChange(value, name);
                    }}
                    inputProps={{
                        maxLength: 10
                    }}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                <FontAwesomeIcon icon={faWhatsapp} />
                            </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Correo Electrónico"
                    variant="outlined"
                    type="email"
                    fullWidth
                    required
                    name="correoElectronico"
                    helperText={!CheckValidEmail() ? "Email no valido" : ""}
                    error={!CheckValidEmail()}
                    value={UserDataRecord.correoElectronico}
                    onChange={e => {
                        const { value, name } = e.target;
                        SetUserDataPropChange(value, name);
                    }}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Contraseña"
                    variant="outlined"
                    type="password"
                    required
                    fullWidth
                    name="password"
                    error={IsUserStrPropValid("password")}
                    value={UserDataRecord.password}
                    onChange={e => {
                        const { value, name } = e.target;
                        SetUserDataPropChange(value, name);
                    }}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                <FontAwesomeIcon icon={faEyeSlash} />
                            </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Confirmar contraseña"
                    variant="outlined"
                    type="password"
                    required
                    fullWidth
                    name="passwordConfirm"
                    error={!(IsPasswordSame())}
                    helperText={!(IsPasswordSame()) ? "La contraseña no coincide" : ""}
                    value={UserDataRecord.passwordConfirm}
                    onChange={e => {
                        const { value, name } = e.target;
                        SetUserDataPropChange(value, name);
                    }}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                <FontAwesomeIcon icon={faLockOpenAlt} />
                            </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <HrefLink href={AppState.Universidad.urlPoliticaPrivacidad} target="_blank">
                    <Typography variant="body1">
                        <FontAwesomeIcon icon={faBookAlt} /> Consulta el aviso de privacidad.
                    </Typography>
                </HrefLink>
            </Grid>
            <Grid item xs={12}>
                <FormControl required variant="outlined">
                    <RadioGroup name="aceptoAviso" value={UserDataRecord.aceptoAviso} onChange={OnUserDataRadioChange}>
                        <FormControlLabel value={GLOBALS.YES_VALUE} control={<Radio />} label="Acepto el aviso de privacidad" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {
                showErroMessage &&
                <Grid item xs={12}>
                    <Typography variant="h6">
                        <FontAwesomeIcon icon={faWarning} /> Ocurrio el siguiente error
                    </Typography>
                    <Typography variant="body1">
                        {`${errorMessage.code} - ${errorMessage.mensaje}`}
                    </Typography>
                </Grid>
            }
            {
                !isLoading ? (
                    <Grid item container xs={12} spacing={3}>
                        <Grid item xs={6} md={6}>
                            <Link to="../" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" fullWidth color="primary">
                                    <Typography variant="body1">
                                        <FontAwesomeIcon icon={faTimes} /> Cancelar
                                    </Typography>
                                </Button>
                            </Link>
                        </Grid>
                        {
                            showLogIn ? (
                                <Grid item xs={6} md={6}>
                                    <Button component={Link} to={`../login`} variant="contained" fullWidth color="secondary">
                                        <Typography variant="body1">
                                            <FontAwesomeIcon icon={faSend} /> Iniciar Sesión
                                        </Typography>
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item xs={6} md={6}>
                                    <Button onClick={onRegistrarClick} variant="contained" fullWidth color="secondary" disabled={!IsUserDataValid()}>
                                        <Typography variant="body1">
                                            <FontAwesomeIcon icon={faSend} /> Registrarse
                                        </Typography>
                                    </Button>
                                </Grid>
                            )
                        }
                        {
                            !showLogIn &&
                            <Grid item xs={6} md={6}>
                                <Button component={Link} to={`../login`} variant="contained" fullWidth color="secondary">
                                    <Typography variant="body1">
                                        <FontAwesomeIcon icon={faSend} /> Iniciar Sesión
                                    </Typography>
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <DialogYesNo
                                ShouldShow={showEndDialog}
                                Title="Registro Completado"
                                Mensaje={[
                                    "El registro ha sido completado con exito ahora puedes iniciar sesión."
                                ]}
                                LabelYes="Iniciar Sesión"
                                LabelNo="No Por el momento"
                                OnNoEventClick={() => { setShowEndDialog(false); }}
                                OnYesEventClick={() => {

                                    navigator("../login");

                                }}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item container xs={12} spacing={3}>
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );

};