import { useContext } from "react";
import { useParams } from "react-router-dom";
import { APIContext } from "../../Contexts/APIContext/APIContext";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { StylerContext } from "../../Contexts/StylerContext/StylerContext";
import { IUniversidadParam } from "../../Interfaces/UrlParams/IUniversidadParam";




export interface IUseGetUniversidadDataHandlers {

    LoadUniversidadAsync: () => Promise<void>;

}


export const useGetUniversidadData = () => {

    const urlParams = useParams<IUniversidadParam>();
    const { AppState, UpdateUniversidadData } = useContext(AppContext);
    const { UpdateStyler } = useContext(StylerContext);
    const { APIService } = useContext(APIContext);


    // console.info("Universidad GUID", urlParams.idUniversidad);

    const loadDataAsync = async (): Promise<void> => {

        // console.info("universidad dAta", AppState.Universidad.nombre);

        if (AppState.Universidad.nombre == undefined) {

            const uniData = await APIService.Universidades.GetUniversidadData(urlParams.idUniversidad as string);
            if (uniData.chainId != undefined) {

                UpdateUniversidadData(uniData);
                UpdateStyler(uniData.nombreThema);
                return;
            }
        }

        return;

    }


    return {
        LoadUniversidadAsync: loadDataAsync
    }

}