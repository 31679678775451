import { faBackward, faUserSlash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { PerfilForm } from "../../Components/Forms/PerfilForm/PerfilForm";
import { BannerRender } from "../../Components/Reusable/Banners/BannerRenders";




export const PerfilPage = () => {

    return (
        <Grid item container spacing={3} sx={{ minHeight: "43rem", padding: "1rem", alignContent: "flex-start", alignItems: "flex-start" }}>
            <Grid item xs={12}>
                <Link to="../hub" style={{ textDecoration: 'none' }}>
                    <Button color="secondary" variant="contained">
                        <Typography variant="subtitle2">
                            <FontAwesomeIcon icon={faBackward} /> Regresar al inicio
                        </Typography>
                    </Button>
                </Link>
            </Grid>
            <Grid item container xs={12} md={8}>
                <PerfilForm />
            </Grid>
            <Grid item xs={12} md={4} container justifyContent="center">
                <BannerRender />
            </Grid>
        </Grid>
    );


};