import { faBackward, faCheckCircle, faListDots, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { IShowResultPageProps } from "./IShowResultPageProps";




export const ShowResultPage = ({

    NumCorrectas,
    NumPreguntas

}: IShowResultPageProps) => {

    const { idExamen } = useParams();
    const toNavigation = idExamen != undefined ? "../../hub" : "../hub";

    return (
        <>
            <Grid container spacing={3} justifyContent="center" alignContent="center" justifyItems="center" sx={{ minHeight: "38rem" }}>
                <Grid item xs={12} >
                    <Typography variant="h4" textAlign="center" >
                        Resultado
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                    <Typography variant="h5" textAlign="center" sx={{ color: "success.main" }}>
                        <FontAwesomeIcon icon={faCheckCircle} />  {`Correctas ${NumCorrectas}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                    <Typography variant="h5" textAlign="center" sx={{ color: "error.main" }}>
                        <FontAwesomeIcon icon={faTimesCircle} />  {`Incorrectas ${NumPreguntas - NumCorrectas}`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" textAlign="center" sx={{ color: "info.main" }}>
                        <FontAwesomeIcon icon={faListDots} />  {`Total de Preguntas ${NumPreguntas}`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent="center" justifyItems="center">
                <Grid item xs={12} md={4}>
                    <Link to={toNavigation} style={{ textDecoration: 'none' }}>
                        <Button color="secondary" variant="contained" fullWidth>
                            <Typography variant="subtitle2">
                                <FontAwesomeIcon icon={faBackward} /> Regresar al Menú Principal
                            </Typography>
                        </Button>
                    </Link>
                </Grid>
            </Grid>

        </>

    );

};