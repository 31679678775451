import { Card, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { HubDrawer } from "../../Components/Navigations/HubDrawer/HubDrawer";



export const MainHubPage = () => {

    return (
        <Grid container spacing={0} alignContent="flex-start">
            <HubDrawer>
                <Outlet />
            </HubDrawer>
        </Grid>
    );


};