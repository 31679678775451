import { faTimer } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { useDidMount } from "../../../Hooks/useDidMount";
import { ITimerCounter } from "./ITimerCounter";




export const TimerCounter = ({

    BeginTime,
    MaxTime,
    OnCountDownDone,

}: ITimerCounter) => {

    const { didMount } = useDidMount();
    const [currentTime, setCurrentTime] = useState<Moment>(BeginTime);
    const [showedTime, setShowedTime] = useState<string>("");
    const intervalId = useRef<number>();


    const InitClock = () => {

        const interval: number = window.setInterval(() => {

            const timePass = moment().diff(BeginTime, 'seconds');
            const secondsPass = moment.duration(timePass, 'seconds').asSeconds();
            const passedTime = moment.utc(secondsPass * 1000).format("HH:mm:ss");
            const hoursPassed = secondsPass / 3600;

            setShowedTime(passedTime);

            if (hoursPassed >= MaxTime) {

                if (OnCountDownDone) {
                    OnCountDownDone();
                }

            }

        }, 1000);

        intervalId.current = interval;

        return () => clearInterval(interval);

    };

    useEffect(() => {

        if (didMount.current) {

            InitClock();

        }

        return function cleanTimer() {
            clearInterval(intervalId.current);
        }

    }, []);

    return (
        <>
            <Grid container spacing={3} justifyContent="flex-end">
                <Grid item xs={12} md={3}>
                    <Typography variant="h6">
                        <FontAwesomeIcon icon={faTimer} /> {showedTime}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );

};