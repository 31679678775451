import { faEnvelope, faEye, faLockOpenAlt, faSend } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../Contexts/AppContext/AppContext";
import { useLoading } from "../../../Hooks/useLoading";
import { useLoginFunctions } from "../../../Hooks/useLoginFunctions";
import { GAEVENTNAMES, GAnalReporter, GAReporterDataContanier } from "../../../Models/config/GEventHandler";
import { GLOBALS } from "../../../Models/config/Globals";
import { HugeButton } from "../../xStyled/HugeButton";
import { ILoginFormProps } from "./ILoginFormProps";




export const LoginForm = ({

    OnLoginError

}: ILoginFormProps) => {

    const navigator = useNavigate();
    const { AppState } = useContext(AppContext);
    const [isLoading, setIsLoading] = useLoading(false);
    const [
        loginData,
        setLoginPropChange,
        isValidEmail,
        isValidLogin,
        isLoginStrPropValid,
        CheckForAuthUser
    ] = useLoginFunctions();


    const OnLoginClick = async () => {

        const response = await CheckForAuthUser();
        // console.info("Respuesta Login", response);
        if (response.chainId == GLOBALS.GUID_DEFAULT) {

            OnLoginError();
            return;
        }

        GAnalReporter(
            AppState.TipoExamen.nombre,
            new GAReporterDataContanier(
                AppState.Universidad.nombre,
                GAEVENTNAMES.SESION_INICIO
            ).ToJSON()
        );

        navigator("../simulador/hub");

    };


    return (
        <Grid container spacing={3} >
            <Grid item xs={12}>
                <Typography variant="h5" textAlign="center">
                    Inicia sesión
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Correo Electrónico"
                    variant="outlined"
                    type="text"
                    required
                    fullWidth
                    name="email"
                    helperText={!isValidEmail(loginData.email) ? "Email no valido" : ""}
                    error={!isValidEmail(loginData.email)}
                    value={loginData.email}
                    onChange={e => {
                        const { value, name } = e.target;
                        setLoginPropChange(value, name);
                    }}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Contraseña"
                    variant="outlined"
                    type="password"
                    required
                    fullWidth
                    name="password"
                    error={isLoginStrPropValid("password")}
                    value={loginData.password}
                    onChange={e => {
                        const { value, name } = e.target;
                        setLoginPropChange(value, name);
                    }}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start" sx={{ color: "primary.main" }}>
                                <FontAwesomeIcon icon={faEye} />
                            </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={6} md={6}>
                <Link to="../restablecer" style={{ textDecoration: 'none' }}>
                    <HugeButton variant="contained" fullWidth color="secondary">
                        <Typography variant="body1">
                            <FontAwesomeIcon icon={faLockOpenAlt} /> Olvide mi contraseña.
                        </Typography>
                    </HugeButton>
                </Link>
            </Grid>
            <Grid item xs={6} md={6}>
                <HugeButton onClick={OnLoginClick} variant="contained" fullWidth color="secondary" disabled={!isValidLogin()}>
                    <Typography variant="body1">
                        <FontAwesomeIcon icon={faSend} /> Iniciar sesión
                    </Typography>
                </HugeButton>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">
                    ¿Aún no tienes cuenta?.
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <Link to="../registro" style={{ textDecoration: 'none' }}>
                    <HugeButton variant="contained" fullWidth color="secondary">
                        <Typography variant="body1">
                            <FontAwesomeIcon icon={faLockOpenAlt} /> Registrate
                        </Typography>
                    </HugeButton>
                </Link>
            </Grid>
        </Grid>
    );

};