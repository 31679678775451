import { createTheme } from "@mui/material";
import { BaseStyleDefiner } from "./BaseStyleDefiner";



let baseTheme = createTheme();
export const LamarTheme = createTheme(baseTheme, {
    palette: {
        ...baseTheme.palette,
        type: 'light',
        primary: {
            main: '#019dcc',
            light: '#00d8e2',
            dark: '#11a2a2',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#002161',
            light: '#40b4b4',
            dark: '#0b7171',
        },
        text: {
            primary: '#272727',
            secondary: '#3d3d3d',
        },
        background: {
            default: '#efefef',
            paper: '#ffffff',
        },
        success: {
            main: '#39b54a',
        },
        error: {
            main: '#ff5b00',
        },
        divider: 'rgba(0,179,216,0.27)',
    },
    typography: {
        h1: {
            fontFamily: 'Montserrat',
        },
        h2: {
            fontFamily: 'Montserrat',
        },
        h3: {
            fontFamily: 'Montserrat',
        },
        h4: {
            fontFamily: 'Montserrat',
        },
        h5: {
            fontFamily: 'Montserrat',
        },
        h6: {
            fontFamily: 'Montserrat',
        },
        button: {
            fontFamily: 'Montserrat',
        },
        subtitle1: {
            fontFamily: 'Source Sans Pro',
        },
        subtitle2: {
            fontFamily: 'Source Sans Pro',
        },
        body1: {
            fontFamily: 'Source Sans Pro',
        },
        body2: {
            fontFamily: 'Source Sans Pro',
        },
        caption: {
            fontFamily: 'Source Sans Pro',
        },
        overline: {
            fontFamily: 'Source Sans Pro',
        },
        fontFamily: 'Source Sans Pro',
    },
});

export class LamarStyleDefinition extends BaseStyleDefiner {

    constructor() {
        super(
            "lamar-style",
            LamarTheme
        );
    }

}