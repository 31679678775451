import { useContext } from "react";
import { APIContext } from "../Contexts/APIContext/APIContext";
import { ICodeResponse } from "../Interfaces/Models/ICodeResponse";
import { IUserData } from "../Interfaces/Models/IUserData";




export const useNewUserRegister = () => {

    const { APIService } = useContext(APIContext);


    const createNewUser = async (userData: IUserData): Promise<ICodeResponse> => {

        return await APIService.UserAuth.RegisterUser(userData);

    };

    return [
        createNewUser
    ] as const;

}