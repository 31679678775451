import styled from "@emotion/styled";





export const ResponsiveImage = styled('img')(({ theme }) => ({

    maxHeight: "350px",

}));