import axios from "axios";
import { ICodeResponse } from "../../Interfaces/Models/ICodeResponse";
import { ILoginData } from "../../Interfaces/Models/ILoginData";
import { IUserData } from "../../Interfaces/Models/IUserData";
import { IUserAuthConnector } from "../Interfaces/IUserAuthConnector";
import { BaseConnector } from "./BaseConnector";



export class UserAuthConnector extends BaseConnector implements IUserAuthConnector {

    constructor() {
        super();
    }

    public async UpdateUser(userData: IUserData): Promise<ICodeResponse> {

        const response = await axios.put('/Registro', userData);
        return response.data;

    }

    public async RequestAuth(loginData: ILoginData): Promise<IUserData> {

        const response = await axios.post('/UserAuth', {
            "email": loginData.email,
            "password": loginData.password,
            "universidadId": loginData.universidadId
        });
        // console.info("Axios Response", response);
        return response.data;

    }

    public async RegisterUser(userData: IUserData): Promise<ICodeResponse> {

        const response = await axios.post('/Registro', {
            "universidadId": userData.universidadId,
            "correoElectronico": userData.correoElectronico,
            "telefono": userData.telefono,
            "nombreCompleto": userData.nombreCompleto,
            "escuelaProcedencia": userData.escuelaProcedencia,
            "password": userData.password
        });
        // console.info("Axios Response", response);
        return response.data;

    }



}