import { createContext, useState } from "react";
import { IWithChildren } from "../../Interfaces/IWithChildren";
import { IExamenTipoRecord } from "../../Interfaces/Models/IExamenTipoRecord";
import { ITipoExamen } from "../../Interfaces/Models/ITipoExamen";
import { IUniversidadData } from "../../Interfaces/Models/IUniversidadData";
import { IUserData } from "../../Interfaces/Models/IUserData";
import { AppStateDefault } from "../../Models/Data/AppStateDefault";
import { IAppContextHandler } from "./IAppContextHandler";
import { IAppContextState } from "./IAppContextState";





export const AppContext = createContext<IAppContextHandler>({} as IAppContextHandler);

export const AppContextProvider = ({ children }: IWithChildren) => {

    const [appState, setAppState] = useState<IAppContextState>(AppStateDefault);

    const updateUniversidadData = (newData: IUniversidadData) => {

        setAppState({
            ...appState,
            Universidad: newData
        });

    };

    const updateUserData = (newData: IUserData) => {

        setAppState({
            ...appState,
            User: newData
        });

    };

    const updateTipoExamen = (newData: IExamenTipoRecord) => {

        setAppState({
            ...appState,
            TipoExamen: newData
        });

    };


    const updateModalidadExamen = (newData: string) => {

        setAppState({
            ...appState,
            ModalidadExamen: newData
        });

    };

    const updateExamenId = (id: number) => {

        setAppState({
            ...appState,
            IdExamen: id
        });

    };


    return (
        <AppContext.Provider value={{
            AppState: appState,
            UpdateUniversidadData: updateUniversidadData,
            UpdateUserData: updateUserData,
            UpdateTipoExamen: updateTipoExamen,
            UpdateModalidadExamen: updateModalidadExamen,
            UpdateExamenId: updateExamenId
        }}>
            {children}
        </AppContext.Provider>
    )

};