import { IUserData } from "../../Interfaces/Models/IUserData";
import { GLOBALS } from "../config/Globals";



export const UserDefault = (idUniversidad?: string): IUserData => {

    return ({
        chainId: "",
        correoElectronico: "",
        escuelaProcedencia: "",
        nombreCompleto: "",
        password: "",
        passwordConfirm: "",
        telefono: "",
        universidadId: idUniversidad ? idUniversidad : "",
        aceptoAviso: GLOBALS.NO_VALUE
    });

}