import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { IntentoTableCell, IntentoTableRow } from "../../xStyled/IntentoTableCell";
import { ResponsiveImage } from "../../xStyled/ResponsiveImage";
import { ITableDetalleExamenResueltoProps } from "./ITableDetalleExamenResueltoProps";

export const TableDetalleExamenResuelto = ({ records }: ITableDetalleExamenResueltoProps) => {

    const headers = [
        'Materia',
        'Pregunta',
        'Respuesta Seleccionada',
        'Correcta',
    ];

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableContainer>
                    <TableHead>
                        <TableRow>
                            {
                                headers.map(header => (
                                    <IntentoTableCell>{header}</IntentoTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            records.length == 0 ? (
                                <IntentoTableRow>
                                    <IntentoTableCell colSpan={headers.length}>
                                        <Typography variant="body1">
                                            <FontAwesomeIcon icon={faEyeSlash} /> Sin registros
                                        </Typography>
                                    </IntentoTableCell>
                                </IntentoTableRow>
                            ) : (
                                records.map((record, index) => (
                                    <IntentoTableRow key={`intento-${record.chainId}`}>
                                        <IntentoTableCell >
                                            <Typography variant="body1">
                                                {record.materia}
                                            </Typography>
                                        </IntentoTableCell>
                                        <IntentoTableCell >
                                            <>
                                                <Typography variant="body1">
                                                    {record.pregunta}
                                                </Typography>
                                                {
                                                    record.urlImagen &&
                                                    <ResponsiveImage src={`${process.env.PUBLIC_URL}/${record.urlImagen}`} />
                                                }
                                            </>
                                        </IntentoTableCell>
                                        <IntentoTableCell >
                                            <Typography variant="body1">
                                                <div dangerouslySetInnerHTML={{ __html: `${record.respuesta}` }}>
                                                </div>
                                            </Typography>
                                        </IntentoTableCell>
                                        <IntentoTableCell >
                                            {
                                                record.correcta ? (
                                                    <Typography variant="body1" sx={{ color: "#32a862" }}>
                                                        <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body1" sx={{ color: "#540b11" }}>
                                                        <FontAwesomeIcon icon={faTimesCircle} size="2x" />
                                                    </Typography>
                                                )
                                            }

                                        </IntentoTableCell>
                                    </IntentoTableRow>
                                ))
                            )
                        }
                    </TableBody>
                </TableContainer>
            </Grid>
        </Grid >
    );

}