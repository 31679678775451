import { createTheme } from "@mui/material/styles";
import { BaseStyleDefiner } from "./BaseStyleDefiner";


let baseTheme = createTheme();
export const UneTheme = createTheme(baseTheme, {
    palette: {
        ...baseTheme.palette,
        primary: {
            main: '#006b5b',
            light: '#33887b',
            dark: '#004a3f',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#ff8f0d',
            light: '#ffa53d',
            dark: '#b26409',
            contrastText: '#000000',
        },
    }
});


export class UneStyleDefinition extends BaseStyleDefiner {

    constructor() {
        super(
            "une-style",
            UneTheme
        );
    }


}