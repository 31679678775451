import { useContext, useState } from "react";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { IUserData } from "../../Interfaces/Models/IUserData";
import { GLOBALS } from "../../Models/config/Globals";
import { UserDefault } from "../../Models/Data/UserDefault";
import { useAuxiliarFunctions } from "../useAuxiliarFunctions";


export interface IUseUserDataRecordHandlers {

    UserDataRecord: IUserData;
    SetUserDataRecord: React.Dispatch<React.SetStateAction<IUserData>>;
    SetUserDataPropChange: (value: any, prop: string) => void;
    OnUserDataRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    OnUserDataCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    IsUserDataValid: () => boolean;
    IsUserStrPropValid: (propName: string) => boolean;
    IsPasswordSame: () => boolean;
    CheckValidEmail: () => boolean;

}


export const useUserDataRecord = (userData?: IUserData): IUseUserDataRecordHandlers => {

    const { AppState } = useContext(AppContext);

    const [userDataRecord, setUserDataRecord] = useState<IUserData>(userData ? userData : UserDefault(AppState.Universidad.chainId));
    const [isStrEmpty, isValidEmail] = useAuxiliarFunctions();


    const onUserDataRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserDataRecord({
            ...userDataRecord,
            [event.target.name]: event.target.value
        });
    };

    const onUserDataCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setUserDataRecord({
            ...userDataRecord,
            [event.target.name]: event.target.checked
        });

    };

    const setUserDataPropChange = (value: any, prop: string) => {

        setUserDataRecord({
            ...userDataRecord,
            [prop]: value
        });

    };


    const isUserStrPropValid = (propName: string): boolean => {

        return isStrEmpty(userDataRecord[propName]);

    };

    const isPasswordSame = (): boolean => {

        return userDataRecord.password == userDataRecord.passwordConfirm;

    };

    const checkValidEmail = (): boolean => {

        return isValidEmail(userDataRecord.correoElectronico);

    };


    const isUserDataValid = (): boolean => {


        if (!checkValidEmail()) {
            return false;
        }

        if (isStrEmpty(userDataRecord.escuelaProcedencia)) {
            return false;
        }

        if (isStrEmpty(userDataRecord.nombreCompleto)) {
            return false;
        }

        if (isStrEmpty(userDataRecord.password)) {
            return false;
        }

        if (isStrEmpty(userDataRecord.passwordConfirm)) {
            return false;
        }

        if (isStrEmpty(userDataRecord.telefono)) {
            return false;
        }

        if (!isPasswordSame()) {
            return false;
        }

        if (userDataRecord.aceptoAviso != GLOBALS.YES_VALUE) {
            return false;
        }

        return true;


    };


    return {
        UserDataRecord: userDataRecord,
        SetUserDataRecord: setUserDataRecord,
        SetUserDataPropChange: setUserDataPropChange,
        OnUserDataRadioChange: onUserDataRadioChange,
        OnUserDataCheckBoxChange: onUserDataCheckBoxChange,
        IsUserDataValid: isUserDataValid,
        IsUserStrPropValid: isUserStrPropValid,
        IsPasswordSame: isPasswordSame,
        CheckValidEmail: checkValidEmail
    }

};