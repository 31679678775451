import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import { LayoutLoader } from './Components/Containers/LayoutLoader/LayoutLoader';
import { APIContextProvider } from './Contexts/APIContext/APIContext';
import { AppContextProvider } from './Contexts/AppContext/AppContext';
import { StylerContextProvider } from './Contexts/StylerContext/StylerContext';
import { AppRoutes } from './Routes/AppRoutes';

ReactDOM.render(
  <React.StrictMode>
    <APIContextProvider>
      <StylerContextProvider>
        <AppContextProvider>
          <Suspense fallback={<LayoutLoader />}>
            <RouterProvider router={AppRoutes()} />
          </Suspense>
        </AppContextProvider>
      </StylerContextProvider>
    </APIContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
