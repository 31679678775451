import { useState } from "react";



export const useLoading = (initialState: boolean) => {

    const [isLoading, setIsLoading] = useState<boolean>(initialState);



    return [
        isLoading,
        setIsLoading
    ] as const;

};