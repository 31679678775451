import { faBars, faClipboard, faHamburger, faUserSlash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, Avatar, Box, Button, Card, CssBaseline, Divider, Drawer, Grid, IconButton, LinearProgress, Stack, Toolbar, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../Contexts/AppContext/AppContext";
import { IWithChildren } from "../../../Interfaces/IWithChildren";
import { GLOBALS } from "../../../Models/config/Globals";
import { BannerRender } from "../../Reusable/Banners/BannerRenders";
import { BorderLinearProgress } from "../../xStyled/BorderLinearProgress";




export const HubDrawer = ({

    children

}: IWithChildren) => {

    const { AppState } = useContext(AppContext);
    const [isMenuOpen, setMenuOpen] = useState(false);

    const onDrawerToggle = () => {
        setMenuOpen(!isMenuOpen);
    };

    const container = undefined;

    const drawerOptions = (
        <>
            <Toolbar sx={{ display: "flex", justifyContent: "center", padding: "1rem" }}>
                <Stack spacing={3}>
                    <Avatar
                        src={`${process.env.PUBLIC_URL}/UI/user.png`}
                        sx={{ width: 150, height: 150 }}
                    />
                    <Typography variant="h6" color="secondary.main" textAlign="center" textTransform="capitalize">
                        {AppState.User.nombreCompleto}
                    </Typography>
                    {/* <BorderLinearProgress variant="determinate" value={30} />
                    <Typography variant="subtitle2" color="primary.main">
                        {`${30}% perfil completado`}
                    </Typography> */}
                    <Link to="perfil" style={{ textDecoration: 'none' }}>
                        <Button color="primary" variant="contained">
                            <Typography variant="subtitle2">
                                <FontAwesomeIcon icon={faClipboard} /> Editar Perfil
                            </Typography>
                        </Button>
                    </Link>
                </Stack>
            </Toolbar>
            <Divider />
            <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <BannerRender />
                    </Grid>
                </Grid>
            </Box>
        </>
    );

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <AppBar
                    color="secondary"
                    position="relative"
                    sx={{
                        width: { sm: `calc(100% - ${GLOBALS.DRAWER_WIDTH}px)` },
                        ml: { sm: `${GLOBALS.DRAWER_WIDTH}px` },
                    }}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={onDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </IconButton>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", flexGrow: "1" }}>
                            <Link to="../" style={{ textDecoration: 'none' }}>
                                <Button color="primary" variant="contained">
                                    <Typography variant="subtitle2">
                                        <FontAwesomeIcon icon={faUserSlash} /> Cerrar Sesión
                                    </Typography>
                                </Button>
                            </Link>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { sm: GLOBALS.DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
                    aria-label="navigation-links"
                >
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={isMenuOpen}
                        onClose={onDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: GLOBALS.DRAWER_WIDTH },
                        }}
                    >
                        {drawerOptions}
                    </Drawer>
                    <Drawer
                        variant="persistent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: GLOBALS.DRAWER_WIDTH,
                                position: "absolute"
                            },
                        }}
                        open
                    >
                        {drawerOptions}
                    </Drawer>
                </Box>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 1,
                    marginLeft: { sm: `${GLOBALS.DRAWER_WIDTH}px` },
                    width: { sm: `calc(100% - ${GLOBALS.DRAWER_WIDTH}px)` },
                    overflowX: "auto",
                    maxHeight: "42rem"
                }}>
                <Card>
                    {children}
                </Card>
            </Box>
        </>
    );

};