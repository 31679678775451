import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { IDialogYesNoProps } from "./IDialogYesNoProps";



export const DialogYesNo = ({

    Title,
    Mensaje,
    ShouldShow,
    LabelNo,
    LabelYes,
    OnNoEventClick,
    OnYesEventClick

}: IDialogYesNoProps) => {

    const handleClose = (event: object, reason: string) => {

        if (reason && reason == "backdropClick")
            return;

    }

    return (
        <Dialog onClose={handleClose} open={ShouldShow}>
            <DialogTitle>
                {Title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {
                        Mensaje.map((msg, index) => (
                            <DialogContentText key={`dialog-text-content-${index}`}>
                                {msg}
                            </DialogContentText>
                        ))
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={OnYesEventClick}>
                    {LabelYes ? LabelYes : "Si"}
                </Button>
                <Button color="secondary" variant="contained" onClick={OnNoEventClick}>
                    {LabelNo ? LabelNo : "No"}
                </Button>
            </DialogActions>
        </Dialog>
    );


};