import { useContext, useState } from "react";
import { APIContext } from "../Contexts/APIContext/APIContext";
import { AppContext } from "../Contexts/AppContext/AppContext";
import { IBateriaData } from "../Interfaces/Models/IBateriaData";




export const useBateriaExamen = () => {

    const { AppState } = useContext(AppContext);
    const { APIService } = useContext(APIContext);

    const [bateriaExamen, setBateriaExamen] = useState<IBateriaData>({
        materias: [],
        preguntas: []
    });

    const LoadBateriaExamen = async () => {

        const response = await APIService.Preguntas.GetPreguntasByExamenType(AppState.TipoExamen.chainId);
        setBateriaExamen(response);
        return response;

    };

    return [
        bateriaExamen,
        LoadBateriaExamen
    ] as const;


};