

export function GAnalReporter(actionName: string, eventParams?: any) {

    gtag('event', actionName, eventParams);

}

export class GAReporterDataContanier {

    uniData: string = "";
    value: any = null;

    constructor(uni: string, valor: any) {

        this.uniData = uni;
        this.value = valor;

    }


    public ToJSON() {

        return {
            "event_category": this.uniData,
            "event_label": this.value
        }

    }

}



export class GAEVENTNAMES {

    public static REGISTRO_INICIADO: string = "Registro Iniciado";
    public static REGISTRO_REALIZADO: string = "Registro Realizado";
    public static SESION_INICIO: string = "Inicio Sesión";
    public static EXAMEN_REALIZAR: string = "Iniciar Examen";
    public static EXAMEN_ABANDONAR: string = "Abandonar Examen";
    public static EXAMEN_GUARDAR: string = "Guardar y Salir";
    public static EXAMEN_CATEGORIA: string = "Realizar Examen";
    public static EXAMEN_CONCLUIDO: string = "Concluir Examen";
    public static BANNER_ANUNCIO: string = "Click Banner";

}