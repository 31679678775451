import { MutableRefObject, useEffect, useRef } from "react";




export const useDidMount = () => {

    const didMount = useRef(false);

    useEffect(() => {

        if (!didMount.current) {
            didMount.current = true;
        }

    });

    return {
        didMount
    };

};