import { GLOBALS } from "../Models/config/Globals";



export const useAuxiliarFunctions = () => {

    const isStrEmpty = (str: string) => {
        return (!str || 0 === str.length);
    }

    const isValidEmail = (str: string) => {
        return GLOBALS.EMAIL_REG_EXP.test(str);
    }

    return [
        isStrEmpty,
        isValidEmail
    ];

};