import { Card, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { RegisterForm } from "../../Components/Forms/RegisterForm/RegisterForm";
import { LoaderIndicator } from "../../Components/Reusable/LoaderIndicator/LoaderIndicator";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { useDidMount } from "../../Hooks/useDidMount";
import { useGetUniversidadData } from "../../Hooks/useGetUniversidadData/useGetUniversidadData";
import { GAEVENTNAMES, GAnalReporter, GAReporterDataContanier } from "../../Models/config/GEventHandler";




export const RegistroPage = () => {

    const { didMount } = useDidMount();
    const { AppState } = useContext(AppContext);
    const { LoadUniversidadAsync } = useGetUniversidadData();
    const [isLoading, setIsLoading] = useState<boolean>(true);



    useEffect(() => {

        if (didMount.current) {

            const checkData = async () => {

                await LoadUniversidadAsync();
                GAnalReporter(
                    AppState.TipoExamen.nombre,
                    new GAReporterDataContanier(
                        AppState.Universidad.nombre,
                        GAEVENTNAMES.REGISTRO_INICIADO
                    ).ToJSON()
                );
                setIsLoading(false);

            }

            checkData().catch(e => {
                console.info("error al obtener datos", e);
            })

        }

    }, []);

    return (
        <LoaderIndicator isLoading={isLoading} message="Cargando Formulario">
            <Card>
                <Grid container spacing={0} sx={{ height: "100%", marginTop: "1.5rem" }}>
                    <Grid item container spacing={0} xs={12}>
                        <Grid item xs={12} md={6} container spacing={3} sx={{
                            background: `url('${process.env.PUBLIC_URL + "/UI/Registro.jpg"}')`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            height: { xs: "25rem", sm: "100%" }
                        }}>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ padding: "1rem" }}>
                            <RegisterForm />
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </LoaderIndicator>
    );

};