import { Grid, Typography } from "@mui/material";
import { height } from "@mui/system";




export const NotFound = () => {

    return (
        <Grid container justifyContent="center" alignItems="center"
            sx={{
                padding: "1rem",
                background: `url('${process.env.PUBLIC_URL}/UI/404Error.jpg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            }}>
            <Grid item xs={12} md={6} container>
                <Grid item xs={12}>
                    <Typography variant="h4" color="white" textAlign="center">
                        404 ERROR
                    </Typography>
                    <Typography variant="h5" color="white" textAlign="center">
                        ¡Lo sentimos, página no encontrada!.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "1.5rem" }}>
                    <Typography variant="body1" color="white" textAlign="center">
                        La página que estas busando no se encuentra disponible, quizas ingresaste mal la url. Verifica que hayas escrito la url correcta.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
            </Grid>
        </Grid>
    );

};