import { Card, Grid } from "@mui/material";
import { useState } from "react";
import { LoginForm } from "../../Components/Forms/LoginForm/LoginForm";
import { DialogError } from "../../Components/Reusable/DialogError/DialogError";




export const LoginPage = () => {

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    return (
        <>
            <Card>
                <Grid container spacing={0} sx={{ height: "100%", marginTop: "1.5rem" }}>
                    <Grid item container spacing={0} xs={12}>
                        <Grid item xs={12} md={6} container spacing={3} sx={{
                            background: `url('${process.env.PUBLIC_URL + "/UI/login.jpg"}')`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            height: { xs: "25rem", sm: "100%" }
                        }}>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ padding: { xs: "1rem", md: "8rem 2rem" } }}>
                            <LoginForm
                                OnLoginError={() => { setShowErrorModal(true) }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Card >
            <DialogError
                Title="Error al ingresar"
                Messages={[
                    '¡Ups! el usuario o contraseña no coincide. Inténtalo de nuevo.'
                ]}
                ShouldShow={showErrorModal}
                OnClose={() => { setShowErrorModal(false) }}
            />
        </>
    );

};