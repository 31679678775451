import { createBrowserRouter, Route, Routes, useRoutes } from "react-router-dom";
import { NotFound } from "../Components/Containers/NotFound/NotFound";
import { PageGeneralError } from "../Components/Containers/PageGeneralError/PageGeneralError";
import { ExamenDetalle } from "../_Pages/Auth/ExamenDetalle/ExamenDetalle";
import { ExamenPage } from "../_Pages/Auth/ExamenPage/ExamenPage";
import { MainHubPage } from "../_Pages/Auth/MainHubPage";
import { MisIntentosPage } from "../_Pages/Auth/MisIntentosPage";
import { PerfilPage } from "../_Pages/Auth/PerfilPage";
import { HomePage } from "../_Pages/Public/HomePage";
import { LoginPage } from "../_Pages/Public/LoginPage";
import { MainContainerPage } from "../_Pages/Public/MainContainerPage";
import { PasswordResetPage } from "../_Pages/Public/PasswordResetPage";
import { RegistroPage } from "../_Pages/Public/RegistroPage";



export const AppRoutes = () => {

    let routes = createBrowserRouter([
        {
            path: "/:idUniversidad",
            element: <MainContainerPage />,
            children: [
                { index: true, element: <HomePage />, errorElement: <PageGeneralError /> },
                { path: "login", element: <LoginPage />, errorElement: <PageGeneralError /> },
                { path: "restablecer", element: <PasswordResetPage />, errorElement: <PageGeneralError /> },
                { path: "registro", element: <RegistroPage />, errorElement: <PageGeneralError /> },
                {
                    path: "simulador", element: <MainHubPage />,
                    errorElement: <PageGeneralError />,
                    children: [
                        { path: "hub", element: <MisIntentosPage /> },
                        { path: "perfil", element: <PerfilPage /> },
                        { path: "examen-simulacion", element: <ExamenPage /> },
                        {
                            path: "examen-prueba", children: [
                                { index: true, element: <ExamenPage IsTest /> },
                                { path: ":idExamen", element: <ExamenPage IsTest /> }
                            ]
                        },
                        {
                            path: "detalle-examen", children: [
                                { path: ":chainId", element: <ExamenDetalle /> }
                            ]
                        }
                    ]
                },
                { path: "*", element: <NotFound /> }
            ],
        },
        {
            path: "/404", element: <MainContainerPage />,
            children: [
                { index: true, element: <NotFound /> }
            ]
        },
        {
            path: "*", element: <MainContainerPage />,
            children: [
                { index: true, element: <NotFound /> }
            ]
        }
    ]);


    return routes;

}