
export const MainContainerStyler = {

    mainBodyBG: {

        backgroundColor: 'primary.main',
        minHeight: '100vh',
        backgroundImage: `url(${process.env.PUBLIC_URL + '/UI/BGCircles.png'});`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        flexDirection: "column",
        padding: { xs: "1rem", md: "3rem 7rem" },

    }

}