import { Theme, ThemeProvider } from "@emotion/react";
import { createContext, useState } from "react";
import { IWithChildren } from "../../Interfaces/IWithChildren";
import { IStyleDefinition } from "../../Theme/BaseStyleDefiner";
import { BritanicaStyleDefinition } from "../../Theme/BritanicaTheme";
import { CJVStyleDefinition } from "../../Theme/CJVTheme";
import { DefaultTheme } from "../../Theme/DefaultTheme";
import { ICELStyleDefinition } from "../../Theme/IcelTheme";
import { LamarStyleDefinition } from "../../Theme/LamarTheme";
import { LucernaStyleDefinition } from "../../Theme/LucernaTheme";
import { SantafeStyleDefinition } from "../../Theme/SantafeTheme";
import { UPOStyleDefinition } from "../../Theme/UPOTheme";
import { UneStyleDefinition } from "../../Theme/UneTheme";
import { UnidepStyleDefinition } from "../../Theme/UnidepTheme";
import { UniverStyleDefinition } from "../../Theme/UniverTheme";
import { IStylerContextHandlers } from "./IStylerContextHandlers";


export const StylerContext = createContext<IStylerContextHandlers>({} as IStylerContextHandlers);

export const StylerContextProvider = ({ children }: IWithChildren) => {

    const [currentStyler, setCurrentStyler] = useState<Theme>(DefaultTheme);

    const SupportedStyles: IStyleDefinition[] = [
        new LucernaStyleDefinition(),
        new UnidepStyleDefinition(),
        new UneStyleDefinition(),
        new ICELStyleDefinition(),
        new LamarStyleDefinition(),
        new UniverStyleDefinition(),
        new SantafeStyleDefinition(),
        new UPOStyleDefinition(),
        new CJVStyleDefinition(),
        new BritanicaStyleDefinition()
    ]

    const updateStyler = (styleName: string) => {

        // console.info("Style", styleName);

        const newStyler = SupportedStyles.filter(style => {
            return style.nombre === styleName;
        });

        if (newStyler.length > 0) {
            setCurrentStyler(newStyler[0].GetTheme());
            return;
        }

        setCurrentStyler(DefaultTheme);

    }

    return (

        <StylerContext.Provider value={{ Styler: currentStyler, UpdateStyler: updateStyler }}>
            <ThemeProvider theme={currentStyler}>
                {children}
            </ThemeProvider>
        </StylerContext.Provider>

    );

}