import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";




export const HugeButton = styled(Button)<ButtonProps>(({ theme }) => ({

    minHeight: "4rem"

}));