import { faBackward } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, LinearProgress, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { BannerRender } from "../../../Components/Reusable/Banners/BannerRenders";
import { TableDetalleExamenResuelto } from "../../../Components/Reusable/TableDetalleExamenResuelto/TableDetalleExamenResuelto";
import { APIContext } from "../../../Contexts/APIContext/APIContext";
import { useDidMount } from "../../../Hooks/useDidMount";
import { useLoading } from "../../../Hooks/useLoading";
import { IExamenDetalleUsuario } from "../../../Interfaces/Models/IExamenDetalleUsuario";
import { GLOBALS } from "../../../Models/config/Globals";




export const ExamenDetalle = () => {

    const { APIService } = useContext(APIContext);

    const [isLoading, setIsLoading] = useLoading(true);
    const [searchParams] = useSearchParams();
    const [showRespuestas, setShowRespuestas] = useState('all');
    const { didMount } = useDidMount();
    const { chainId } = useParams();
    const [records, setRecords] = useState<IExamenDetalleUsuario[]>([]);
    const [filteredRecords, setFilteredRecords] = useState<IExamenDetalleUsuario[]>([]);

    useEffect(() => {

        if (didMount.current) {

            const queryData = async () => {

                const idExamen = searchParams.get('id');
                // console.info("Chain Id", chainId);
                const response = await APIService.Preguntas.GetRespuestasExamenById(chainId as string, Number(idExamen));
                setRecords(response);
                setIsLoading(false);
                setFilteredRecords(response);

            };

            queryData().catch(e => {
                console.info("Error obteniendo datos", e);
            })


        }


    }, []);


    const getByCorrecta = (isCorrecta: boolean) => {

        const filter = records.filter(record => {
            return record.correcta == isCorrecta;
        });

        return filter;

    }


    const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newSort: string) => {
        setShowRespuestas(newSort);

        if (records.length > 0) {

            switch (newSort) {
                case 'incorrectas':
                    setFilteredRecords(getByCorrecta(false));
                    break;
                case 'correctas':
                    setFilteredRecords(getByCorrecta(true));
                    break;
                default:
                    setFilteredRecords(records);
                    break;
            }

        }

    };


    return (
        <Grid container spacing={0} sx={{ padding: "1rem" }}>
            {
                isLoading ? (
                    <Grid item xs={12}>
                        <Typography variant="h6" textAlign="center">
                            Obteniendo Resultados...
                        </Typography>
                        <LinearProgress />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Link to="../../hub" style={{ textDecoration: 'none' }}>
                                        <Button color="secondary" variant="contained">
                                            <Typography variant="subtitle2">
                                                <FontAwesomeIcon icon={faBackward} /> Regresar al inicio
                                            </Typography>
                                        </Button>
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5" textAlign="center">
                                        Detalle del examen
                                    </Typography>
                                    <Typography variant="body1" textAlign="center">
                                    </Typography>
                                </Grid >
                            </Grid >
                        </Grid >
                        <Grid item xs={12} sx={{ marginTop: "2rem" }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={showRespuestas}
                                        exclusive
                                        onChange={handleToggleChange}
                                    >
                                        <ToggleButton value="all">Mostrar todos</ToggleButton>
                                        <ToggleButton value="incorrectas">Mostrar incorrectas</ToggleButton>
                                        <ToggleButton value="correctas">Mostrar correctas</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableDetalleExamenResuelto
                                        records={filteredRecords}
                                    />
                                </Grid>
                            </Grid >
                        </Grid>
                        <Grid item container xs={12} justifyContent="center" sx={{ marginTop: "2rem" }}>
                            <BannerRender BannerType={GLOBALS.BANNER_HORIZONTAL} />
                        </Grid>
                    </>
                )
            }
        </Grid >
    )

}