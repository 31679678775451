import { faBackward, faBackwardStep, faCheckToSlot, faForwardStep } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControl, FormControlLabel, FormLabel, Grid, LinearProgress, Radio, RadioGroup, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ButtonSaveExamen } from "../../../Components/Reusable/ButtonSaveExamen/ButtonSaveExamen";
import { DialogYesNo } from "../../../Components/Reusable/DialogYesNo/DialogYesNo";
import { ShowResultPage } from "../../../Components/Reusable/ShowResultPage/ShowResultPage";
import { TimerCounter } from "../../../Components/Reusable/TimerCounter/TimerCounter";
import { APIContext } from "../../../Contexts/APIContext/APIContext";
import { AppContext } from "../../../Contexts/AppContext/AppContext";
import { useBateriaExamen } from "../../../Hooks/useBateriaExamen";
import { useLoading } from "../../../Hooks/useLoading";
import { useUserRespuestas } from "../../../Hooks/useUserRespuestas";
import { IRespuestaData } from "../../../Interfaces/Models/IRespuestaData";
import { GAnalReporter, GAEVENTNAMES, GAReporterDataContanier } from "../../../Models/config/GEventHandler";
import { GLOBALS } from "../../../Models/config/Globals";
import { IExamenPageProps } from "./IExamenPageProps";




export const ExamenPage = ({

    IsTest

}: IExamenPageProps) => {

    const { idExamen } = useParams<string>();
    const navigator = useNavigate();
    const [searchParams] = useSearchParams();

    const { AppState } = useContext(AppContext);
    const { APIService } = useContext(APIContext);

    const [userRespuestas, updateUserRespuestas, GetResult, LoadUserRespuestaPrevias] = useUserRespuestas();
    const [isLoading, setIsLoading] = useLoading(true);
    const [bateriaExamen, LoadBateriaExamen] = useBateriaExamen();
    const [currentMateria, setCurrentMateria] = useState<number>(0);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
    const [showAbandonDialog, setShowAbandonDialog] = useState<boolean>(false);
    const [showEndDialog, setShowEndDialog] = useState<boolean>(false);
    const [beginTime, setBeginTime] = useState<Moment>(moment());


    const newMateriaSelection = (event: React.MouseEvent<HTMLElement>, newValue: string) => {

        if (newValue !== null) {
            setCurrentMateria(parseInt(newValue));
        }

    };

    const onNavigateMaterias = (newValue: number) => () => {

        setCurrentMateria(currentMateria + newValue);

    };

    const getPreguntasByMateria = () => {

        return bateriaExamen.preguntas.filter(pregunta => {
            return pregunta.idMateria == bateriaExamen.materias[currentMateria].idMateria;
        })

    };


    const onRespuestaSelected = (idPregunta: number) => (event: React.ChangeEvent<HTMLInputElement>) => {

        const idRespuesta = parseInt((event.target as HTMLInputElement).value, 10);
        const preguntaSet = bateriaExamen.preguntas.find(item => item.idPregunta === idPregunta);
        const respuestaSet = preguntaSet?.respuestas.find(respuesta => respuesta.idRespuesta === idRespuesta);

        const isCorrecto = respuestaSet?.correcta as boolean;
        const respuestaText = respuestaSet?.respuesta as string;

        updateUserRespuestas(idPregunta, idRespuesta, isCorrecto, respuestaText);
        // console.info("respuestas del usuario", userRespuestas);

    };


    const SendSaveRequestToServer = async (idStatus: number) => {

        setIsLoading(true);
        const response = await APIService.Preguntas.SendRespuestasUsuarios(
            AppState.User.chainId,
            beginTime.format(GLOBALS.FORMAT_FECHA),
            moment().format(GLOBALS.FORMAT_FECHA),
            `${GetResult()} / ${bateriaExamen.preguntas.length}`,
            userRespuestas,
            AppState.IdExamen,
            idStatus
        );
        return response;

    };

    const updateRespuestasRequest = async (idStatus: number) => {

        setIsLoading(true);
        const response = await APIService.Preguntas.UpdateRespuestasUsuario(
            `${idExamen}`,
            moment().format(GLOBALS.FORMAT_FECHA),
            `${GetResult()} / ${bateriaExamen.preguntas.length}`,
            userRespuestas,
            idStatus
        );
        return response;

    }

    const CleanDialogs = () => {

        setShowEndDialog(false);
        setShowAbandonDialog(false);
        setShowSaveDialog(false);

    };


    const onConcludedExam = async () => {

        // console.info("Examen terminado");
        if (idExamen) {

            await updateRespuestasRequest(GLOBALS.ID_ESTATUS_COMPLETADO);

        } else {

            await SendSaveRequestToServer(GLOBALS.ID_ESTATUS_COMPLETADO);

        }

        CleanDialogs();
        setIsLoading(false);
        setShowResult(true);

    };


    const IsRespuestaSelected = (respuesta: number): any => {

        for (let index = 0; index < userRespuestas.length; index++) {
            const response = userRespuestas[index];

            if (response.idPregunta == respuesta) {
                return response.idRespuesta.toString();
            }
        }

        return null;

    }

    const renderSaveExamenButton = () => {

        if (IsTest) {

            return (
                <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "2rem", display: "flex", justifyContent: "flex-end" }}>
                    <ButtonSaveExamen
                        OnSave={() => setShowSaveDialog(true)}
                        OnFinalizar={() => setShowEndDialog(true)}
                    />
                </Grid>
            );

        }

    }



    const OnSaveExamenClick = async () => {

        // console.info("Id Examen", idExamen);
        setIsLoading(true);
        if (idExamen) {

            await updateRespuestasRequest(GLOBALS.ID_ESTATUS_NO_COMPLETADO);

        } else {

            await SendSaveRequestToServer(GLOBALS.ID_ESTATUS_NO_COMPLETADO);

        }

        CleanDialogs();
        setIsLoading(false);

        if (idExamen) {
            navigator("../../hub");
        } else {
            navigator("../hub");
        }

    };


    useEffect(() => {

        const initialData = async () => {

            if (idExamen) {

                const asyncBateria = await LoadBateriaExamen();
                await LoadUserRespuestaPrevias(idExamen, asyncBateria.preguntas);
                const date = searchParams.get("date");
                setBeginTime(date ? moment(date) : moment());
                setIsLoading(false);
                return;
            }

            await LoadBateriaExamen();
            setIsLoading(false);
        }

        initialData().catch();

    }, []);

    return (
        <Grid container spacing={0} sx={{ padding: "1rem" }}>
            {
                isLoading ? (
                    <Grid item xs={12}>
                        <Typography variant="h5" textAlign="center">
                            Cargando Preguntas....
                        </Typography>
                        <LinearProgress />
                    </Grid>
                ) : (
                    <>
                        {
                            showResult ? (
                                <ShowResultPage
                                    NumCorrectas={GetResult()}
                                    NumPreguntas={bateriaExamen.preguntas.length}
                                />
                            ) : (
                                <Grid item xs={12} container spacing={3}>
                                    <Grid item xs={12} container>
                                        <Grid item xs={4} md={4}>
                                            <Button color="secondary" variant="contained" onClick={() => { setShowAbandonDialog(true); }}>
                                                <Typography variant="subtitle2">
                                                    <FontAwesomeIcon icon={faBackward} /> Abandonar Examen
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4} md={4}></Grid>
                                        {
                                            renderSaveExamenButton()
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" textAlign="center" color="primary">
                                            {IsTest ? `Examen de Practica` : `Simulación de Examen`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TimerCounter
                                            BeginTime={beginTime}
                                            MaxTime={IsTest ? GLOBALS.TIMER_UNLIMITED : GLOBALS.TIMER_LIMITED}
                                            OnCountDownDone={onConcludedExam}
                                        />
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center">
                                        <Grid item xs={12} sx={{ overflowX: "scroll" }}>
                                            <ToggleButtonGroup
                                                color="secondary"
                                                value={currentMateria.toString()}
                                                exclusive
                                                onChange={newMateriaSelection}>
                                                {
                                                    bateriaExamen.materias.map((cMateria, mIndex) => (
                                                        <ToggleButton key={`optionIndexed-${mIndex}`} value={`${mIndex}`}>{cMateria.nombre}</ToggleButton>
                                                    ))
                                                }
                                            </ToggleButtonGroup>
                                        </Grid>
                                        {
                                            getPreguntasByMateria().map((pregunta, pIndex) => (
                                                <Grid item xs={12} sx={{ marginTop: "2rem" }}>
                                                    <FormControl key={`pregunta-${pIndex}-${pregunta.idMateria}`} variant="standard">
                                                        <FormLabel id={`label-question-${pIndex}`} sx={{ marginBottom: "1rem" }}>
                                                            <Typography variant="body1" fontWeight="bolder">
                                                                {`${pIndex + 1}) ${pregunta.pregunta}`}
                                                                {
                                                                    pregunta.ejercicios.map((ejercicio, index) => (
                                                                        <a key={`ejercicio-url-${index}`} href={`${process.env.PUBLIC_URL}/${ejercicio.urlEjericio}`} target="_blank">
                                                                            {` Ver complemento ${index + 1} `}
                                                                        </a>
                                                                    ))
                                                                }
                                                            </Typography>
                                                        </FormLabel>
                                                        {
                                                            pregunta.urlImagen != "" &&
                                                            <img src={`${process.env.PUBLIC_URL}/${pregunta.urlImagen}`} style={{ width: "100%" }} />
                                                        }
                                                        <RadioGroup
                                                            onChange={onRespuestaSelected(pregunta.idPregunta)}
                                                            aria-labelledby={`label-question-${pIndex}`}
                                                            defaultValue={IsRespuestaSelected(pregunta.idPregunta)}
                                                        >
                                                            {
                                                                pregunta.respuestas.map((respuesta, rIndex) => (
                                                                    <FormControlLabel
                                                                        key={`respuesta-${respuesta.idRespuesta}`}
                                                                        value={respuesta.idRespuesta.toString()}
                                                                        label={
                                                                            <Typography variant="body2">
                                                                                <div dangerouslySetInnerHTML={{ __html: `${String.fromCharCode(65 + rIndex)}) ${respuesta.respuesta}` }}>
                                                                                </div>
                                                                                {
                                                                                    respuesta.urlImagen && respuesta.urlImagen != "" &&
                                                                                    <img src={`${process.env.PUBLIC_URL}/${respuesta.urlImagen}`} style={{ width: "100%" }} />
                                                                                }
                                                                            </Typography>
                                                                        }
                                                                        control={<Radio />}
                                                                    />
                                                                ))
                                                            }
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                    {
                                        IsTest &&
                                        <Grid item xs={12} container spacing={3}>
                                            <Grid item xs={4} md={4}></Grid>
                                            <Grid item xs={4} md={4}></Grid>
                                            {
                                                renderSaveExamenButton()
                                            }
                                        </Grid>
                                    }
                                    <Grid item xs={12} container spacing={3}>
                                        <Grid item xs={4} sm={4} md={4}>
                                            {
                                                currentMateria > 0 &&
                                                <Button variant="contained" color="secondary" fullWidth onClick={onNavigateMaterias(-1)}>
                                                    <Typography variant="body1">
                                                        <FontAwesomeIcon icon={faBackwardStep} /> Regresar
                                                    </Typography>
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            {
                                                currentMateria < bateriaExamen.materias.length - 1 ? (
                                                    <Button variant="contained" color="secondary" fullWidth onClick={onNavigateMaterias(1)}>
                                                        <Typography variant="body1">
                                                            <FontAwesomeIcon icon={faForwardStep} /> Siguiente
                                                        </Typography>
                                                    </Button>
                                                ) : (
                                                    <Button variant="contained" color="primary" fullWidth onClick={() => { setShowEndDialog(true); }}>
                                                        <Typography variant="body1">
                                                            <FontAwesomeIcon icon={faCheckToSlot} /> Finalizar
                                                        </Typography>
                                                    </Button>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DialogYesNo
                                            ShouldShow={showEndDialog}
                                            Title="CONCLUIR EXAMEN"
                                            Mensaje={[
                                                "¿Seguro que quieres salir?",
                                                "Todo lo que no hayas respondido se tomará como incorrecto."
                                            ]}
                                            OnNoEventClick={() => { setShowEndDialog(false); }}
                                            OnYesEventClick={() => {

                                                GAnalReporter(
                                                    AppState.TipoExamen.nombre,
                                                    new GAReporterDataContanier(
                                                        AppState.Universidad.nombre,
                                                        GAEVENTNAMES.EXAMEN_CONCLUIDO
                                                    ).ToJSON()
                                                );
                                                onConcludedExam();

                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DialogYesNo
                                            ShouldShow={showAbandonDialog}
                                            Title="ABANDONAR EXAMEN"
                                            Mensaje={[
                                                "¿Seguro que quieres salir?",
                                                "Todo lo que no hayas respondido se tomará como incorrecto."
                                            ]}
                                            OnNoEventClick={() => { setShowAbandonDialog(false); }}
                                            OnYesEventClick={() => {

                                                GAnalReporter(
                                                    AppState.TipoExamen.nombre,
                                                    new GAReporterDataContanier(
                                                        AppState.Universidad.nombre,
                                                        GAEVENTNAMES.EXAMEN_ABANDONAR
                                                    ).ToJSON()
                                                );
                                                onConcludedExam();

                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DialogYesNo
                                            ShouldShow={showSaveDialog}
                                            Title="¿Deseas Pausar?"
                                            Mensaje={[
                                                "¡Date un break!",
                                                "Da clic y guarda tu examen para reanudarlo más al rato."
                                            ]}
                                            OnNoEventClick={() => { setShowSaveDialog(false); }}
                                            OnYesEventClick={() => {

                                                GAnalReporter(
                                                    AppState.TipoExamen.nombre,
                                                    new GAReporterDataContanier(
                                                        AppState.Universidad.nombre,
                                                        GAEVENTNAMES.EXAMEN_GUARDAR
                                                    ).ToJSON()
                                                );
                                                OnSaveExamenClick();

                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        }
                    </>
                )
            }
        </Grid>
    );

};